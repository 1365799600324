import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiPostReceipt, apiPostTransfer } from "../api";
import { errorHandler } from "../utils/errorHandling";

const financialInitialState = {
	receipt: null,
	receiptID: null,
	isLoadingReceipt: false,
	isLoadingTransfer: false,
};

export const uploadReceipt = createAsyncThunk(
	"financial/uploadReceipt",
	async ({ receipt, signal, onUploadProgress }, { dispatch }) => {
		try {
			dispatch(setIsLoadingReceipt(true));

			const response = await apiPostReceipt(
				{ receipt },
				{
					onUploadProgress,
					signal,
				},
			);

			onUploadProgress(100);

			return response.data;
		} catch (e) {
			if (e.message !== "canceled") {
				errorHandler(e);
			}
		} finally {
			dispatch(setIsLoadingReceipt(false));
		}
	},
);

export const createTransfer = createAsyncThunk(
	"financial/createTransfer",
	async ({ transfer, onSuccess }, { dispatch }) => {
		try {
			dispatch(setIsLoadingTransfer(true));

			const response = await apiPostTransfer(transfer);

			onSuccess && onSuccess();

			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setIsLoadingTransfer(false));
		}
	},
);

const financialSlice = createSlice({
	name: "settings",
	initialState: financialInitialState,
	reducers: {
		resetReceipt: (state) => {
			state.receipt = null;
		},
		setIsLoadingReceipt: (state, { payload }) => {
			state.isLoadingReceipt = payload;
		},
		setIsLoadingTransfer: (state, { payload }) => {
			state.isLoadingTransfer = payload;
		},
		resetFinancial: (state) => {
			state.isLoadingReceipt = false;
			state.receipt = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(uploadReceipt.fulfilled, (state, { payload }) => {
			state.receiptID = payload;
		});
		builder.addCase(createTransfer.fulfilled, (state) => {
			state.receipt = null;
			state.receiptID = null;
		});
	},
});

export const financialReducer = financialSlice.reducer;
export const {
	setIsLoadingReceipt,
	resetReceipt,
	resetFinancial,
	setIsLoadingTransfer,
} = financialSlice.actions;
