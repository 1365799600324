import { combineReducers } from "redux";
import { authReducer, resetAuth } from "./authSlice";
import { offersReducer, resetOffers } from "./offersSlice";
import {
	customerPersonasReducer,
	resetCustomerPersonas,
} from "./customerPersonasSlice";
import { performanceReducer, resetPerformance } from "./performanceSlice";
import { competitorsReducer, resetCompetitors } from "./competitorsSlice";
import {
	customerBehaviorReducer,
	resetCustomerBehavior,
} from "./customerBehaviorSlice";
import { uiReducer, resetUi } from "./uiSlice";
import { homeReducer, resetHome } from "./homeSlice";
import { brandsReducer, resetBrands } from "./brandsSlice";
import { settingsReducer, resetSettings } from "./settingSlice";
import * as Sentry from "@sentry/react";
import { financialReducer, resetFinancial } from "./financialSlice";

const rootReducer = combineReducers({
	auth: authReducer,
	offers: offersReducer,
	customerPersonas: customerPersonasReducer,
	performance: performanceReducer,
	competitors: competitorsReducer,
	customerBehavior: customerBehaviorReducer,
	home: homeReducer,
	brands: brandsReducer,
	ui: uiReducer,
	settings: settingsReducer,
	financial: financialReducer,
});

export const resetStore = () => (dispatch) => {
	dispatch(resetOffers());
	dispatch(resetAuth());
	dispatch(resetCustomerPersonas());
	dispatch(resetCustomerBehavior());
	dispatch(resetPerformance());
	dispatch(resetCompetitors());
	dispatch(resetHome());
	dispatch(resetBrands());
	dispatch(resetUi());
	dispatch(resetSettings());
	dispatch(resetFinancial());
	Sentry.setUser(null);
};

export default rootReducer;
