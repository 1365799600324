export const TYPES_CUSTOMER_BEHAVIOR_STATISTIC = [
	{ name: "AOV", key: "aov" },
	{ name: "No. of transactions", key: "noTransactions" },
	{ name: "Spend", key: "spend" },
];

export const FILTERS_COMPETITION = {
	gender: [
		{ id: 1, name: "Male" },
		{ id: 2, name: "Female" },
	],
	age: [
		{ id: 3, name: "0-18" },
		{ id: 4, name: "19-24" },
		{ id: 5, name: "25-35" },
		{ id: 6, name: "36-45" },
		{ id: 7, name: "46-60" },
		{ id: 8, name: "61+" },
	],
	location: [
		{ id: 9, name: "Riyadh" },
		{ id: 10, name: "Jeddah" },
		{ id: 11, name: "Eastern Province" },
		{ id: 12, name: "Other" },
	],
};

export const FILTER_COMPETITION_DEFAULT_SELECTED_VALUES = {
	age: {},
	location: {},
	gender: {},
};

export const AUTH_SWIPER_SLIDES = [
	"Unlock Your\nMarketing Potential:\nSign Up for Tailored\nCampaigns",
	"Empower Your\nBusiness: Join\nMthmr for Targeted\nMarketing Solutions",
	"Take Control of Your\nMarketing Strategy:\nRegister for\nPersonalized Offers",
	"Boost Your Sales\nwith Precision\nMarketing: Get\nStarted with Mthmr",
];

export const SIGN_UP_SUBSCRIBE_ITEMS = [
	"Tailored campaigns to target specific customer segments",
	"Insights into campaign performance and consumer behavior",
	"Reach customers of competitors with tailored offers",
	"Precision targeting based on demographics and purchase history",
];

export const APPROVAL_IDS = {
	1: "In Review",
	2: "Unverified",
	3: "Verified",
	4: "Active", // TODO: dummy until we have the subscription status from the BE
};

export const ADD_FUNDS_DETAILS = {
	bankName: "Arab National Bank",
	accountName: "Mthmr Tech",
	accountNumber: "0108095383900017",
	ibanNumber: "SA7930400108095383900017",
};
