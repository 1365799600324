import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TextInputNew } from "../components/TextInputNew";
import { ModalContainer } from "./ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import {
	createBrand,
	fetchCreateBrandData,
	resetCreateBrandPdf,
	resetCreateBrandPdfCertificate,
	uploadCertificate,
} from "../store/brandsSlice";
import { showSnackBar } from "../store/uiSlice";
import { LoadingButton } from "@mui/lab";
import { LogoUploader } from "components/LogoUploader";
import { MultiSelectCompetitors } from "components/MultiSelectCompetitors";
import ReorderCompetitors from "components/ReorderCompetitors";
import { FileUpload } from "components/FileUpload";
import { Select } from "components/Select";
import { validateForm } from "utils/validate";

const initialNewBrand = {
	name: "",
	domain: "",
	categoryId: "",
};

const initialErrors = {
	name: [],
	domain: [],
	categoryId: [],
};

export const CreateBrand = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		categories,
		isLoadingBrand,
		allCompetitors,
		isLoadingCertificate,
		isLoadingCreateBrandData,
	} = useSelector((state) => state.brands);
	const userId = useSelector((state) => state.settings.userDetails.id);
	const certificatePdfNumber = useSelector(
		(state) => state.brands.createBrandPdf.certificate,
	);
	const [newBrand, setNewBrand] = useState(initialNewBrand);
	const [logo, setLogo] = useState(null);
	const [uploadCertificateProgress, setUploadCertificateProgress] = useState(0);
	const [certificate, setCertificate] = useState(null);
	const [selectedCompetitors, setSelectedCompetitors] = useState([]);
	const [errors, setErrors] = useState(initialErrors);
	const [errorLogo, setErrorLogo] = useState("");
	const [errorCertificate, setErrorCertificate] = useState("");
	const [touched, setTouched] = useState({});

	const abortControllerRef = useRef(null);

	const isCertificateUploading = useMemo(
		() =>
			isLoadingCertificate ||
			(uploadCertificateProgress > 0 && uploadCertificateProgress < 100),
		[isLoadingCertificate, uploadCertificateProgress],
	);

	const handleClose = () => {
		navigate(-1);
		setNewBrand(initialNewBrand);
		setErrors(initialErrors);
		dispatch(resetCreateBrandPdf());
	};

	const handleCreate = (e) => {
		e.preventDefault();

		const isFormValid = handleSubmitValidation();

		// if user pressed on continue we will show all errors
		setTouched({ domain: true, name: true, categoryId: true });

		if (!isFormValid) {
			return;
		}

		const onSuccess = () => {
			dispatch(
				showSnackBar({
					text: `Brand ${newBrand.name} created successfully`,
					severity: "success",
					duration: 3000,
				}),
			);
			handleClose();
		};

		dispatch(
			createBrand({
				brand: {
					...newBrand,
					picture: logo.file,
					certificatePdf: certificatePdfNumber,
					top5competitors: selectedCompetitors
						.map((competitor) => competitor.id)
						.join(","),
				},
				onSuccess,
			}),
		);
	};

	const handleChangeNewBrand = useCallback(
		(e) => {
			const { name, value } = e.target;

			setTouched((prev) => ({
				...prev,
				[name]: true,
			}));

			setNewBrand((prev) => ({ ...prev, [name]: value }));
		},
		[setTouched, setNewBrand],
	);

	useEffect(() => {
		if (!categories.length && !allCompetitors.length) {
			dispatch(fetchCreateBrandData());
		}
	}, [dispatch]);

	const handleReorderCompetitors = (startIndex, endIndex) => {
		const reorderedCompetitors = Array.from(selectedCompetitors);
		const [removed] = reorderedCompetitors.splice(startIndex, 1);
		reorderedCompetitors.splice(endIndex, 0, removed);
		setSelectedCompetitors(reorderedCompetitors);
	};

	const handleChangeLogo = (newLogo) => {
		setLogo(newLogo);

		if (errorLogo) {
			setErrorLogo("");
		}
	};

	const handleChangeCertificate = (newCertificate) => {
		setCertificate(newCertificate);
		setUploadCertificateProgress(0);

		if (!newCertificate) {
			dispatch(resetCreateBrandPdfCertificate());
			return;
		}

		if (errorCertificate) {
			setErrorCertificate("");
		}

		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		const abortController = new AbortController();
		abortControllerRef.current = abortController;

		dispatch(
			uploadCertificate({
				certificate: newCertificate,
				signal: abortController.signal,
				onUploadProgress: (progress) => {
					setUploadCertificateProgress(progress);
				},
			}),
		);
	};

	const handleChangeSelectedCompetitors = (newSelectedCompetitors) => {
		if (newSelectedCompetitors.length > 5) {
			return;
		}

		setSelectedCompetitors(newSelectedCompetitors);
	};

	const handleValidation = (newBrand) => {
		const newErrors = validateForm(newBrand, "createBrand");

		// Include all fields in filteredErrors, but only include errors for fields that have been touched
		const filteredErrors = Object.keys(newErrors).reduce((obj, key) => {
			return {
				...obj,
				[key]: touched[key] ? newErrors[key] : [],
			};
		}, {});

		setErrors(filteredErrors);
	};

	const handleSubmitValidation = () => {
		const newErrors = validateForm(newBrand, "createBrand");

		setErrors(newErrors);

		if (!logo) {
			setErrorLogo("Please upload the brand logo");
		}

		if (!certificate) {
			setErrorCertificate(
				"Please upload the commercial registration certificate",
			);
		}

		const noErrors = Object.keys(newErrors).every(
			(key) => !newErrors[key].length,
		);

		if (noErrors && certificate && logo) {
			return true;
		}

		return false;
	};

	useEffect(() => {
		handleValidation(newBrand);
	}, [newBrand]);

	return (
		<ModalContainer title={`Add New Brand`} handleClose={handleClose}>
			<Box component="form" onSubmit={handleCreate}>
				<Box
					sx={{
						mb: "24px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<LogoUploader
							logo={logo}
							setLogo={handleChangeLogo}
							containerStyle={{ mr: "24px", height: "70px", width: "70px" }}
							isError={errorLogo}
						/>
						<TextInputNew
							label="Brand Name"
							name="name"
							value={newBrand.name}
							onChange={handleChangeNewBrand}
							containerStyle={{ flex: 1 }}
							isError={!!errors.name.length}
						/>
					</Box>
					{[errorLogo, ...errors.name].map((error) => (
						<Box
							key={`name-${error}`}
							sx={{
								display: "flex",
								mt: "5px",
							}}
						>
							<Typography
								sx={{
									fontSize: "14px",
									lineHeight: "17px",
									fontWeight: "500",
									color: "#EF5457",
								}}
							>
								{error}
							</Typography>
						</Box>
					))}
				</Box>
				<FileUpload
					label="Commercial Registration Certificate"
					uploadProgress={uploadCertificateProgress}
					file={certificate}
					onChangeFile={handleChangeCertificate}
					containerStyle={{ mb: "24px" }}
					error={errorCertificate}
					abortControllerRef={abortControllerRef}
				/>
				<TextInputNew
					label="Brand Domain"
					name="domain"
					value={newBrand.domain}
					onChange={handleChangeNewBrand}
					containerStyle={{ mb: "24px" }}
					errors={errors.domain}
				/>
				<Select
					isLoading={isLoadingCreateBrandData}
					items={categories}
					value={newBrand.categoryId}
					onChange={handleChangeNewBrand}
					name={"categoryId"}
					label={"Brand Category"}
					errors={errors.categoryId}
				/>
				<MultiSelectCompetitors
					competitors={allCompetitors}
					name={"Top 5 Competitors"}
					isLoading={isLoadingCreateBrandData}
					selectedItems={selectedCompetitors}
					setSelectedItems={handleChangeSelectedCompetitors}
				/>
				<ReorderCompetitors
					competitors={selectedCompetitors}
					setCompetitors={setSelectedCompetitors}
					onReorder={handleReorderCompetitors}
					containerStyle={{ mt: "24px" }}
				/>
				<Box sx={{ display: "flex", justifyContent: "flex-end", mt: "24px" }}>
					<LoadingButton
						sx={{
							height: "40px",
							borderRadius: "10px",
							width: "238px",
						}}
						loading={isLoadingBrand || isCertificateUploading}
						variant="contained"
						type="submit"
					>
						<Typography
							sx={{
								fontFamily: "Nunito",
								fontWeight: "600",
								fontSize: "16px",
								textTransform: "none",
							}}
						>
							Continue
						</Typography>
					</LoadingButton>
				</Box>
			</Box>
		</ModalContainer>
	);
};
