import axios from "axios";
import { errorHandler } from "../utils/errorHandling";

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json",
	},
	paramsSerializer: {
		indexes: null,
	},
});

export const callApiWithToken = async (token, url, options) => {
	return axiosInstance({
		...options,
		url,
		headers: {
			authorization: `Bearer ${token}`,
			...options.headers,
		},
	});
};

/**
 * Custom error class for password-related errors
 */
class PasswordError extends Error {
	/**
	 * @param {string} message - Error message
	 * @param {string} field - The field with the error
	 */
	constructor(message, field) {
		super(message);
		this.name = "PasswordError";
		this.isPasswordError = true;
		this.field = field;
	}
}

export const authorizedRequest = async (url, options) => {
	const accessToken = localStorage.getItem("accessToken");

	if (accessToken) {
		try {
			return await callApiWithToken(accessToken, url, options);
		} catch (e) {
			// Check if the error is from a password change endpoint
			const isPasswordChangeError = url.includes("password");

			if (e.response?.status === 401) {
				if (isPasswordChangeError && e.response?.data?.password) {
					const errorMessage = e.response.data.password[0];

					const passwordError = new PasswordError(errorMessage, "oldPassword");

					import("../store").then((module) => {
						const store = module.default;
						import("../store/uiSlice").then((uiModule) => {
							const showSnackBar = uiModule.showSnackBar;
							store.dispatch(
								showSnackBar({
									text: errorMessage,
									severity: "error",
									duration: 5000,
								}),
							);
						});
					});

					return Promise.reject(passwordError);
				}

				// For other 401 errors, reset the store (logout)
				const storeModule = await import("../store");
				const store = storeModule.default;
				const { resetStore } = await import("../store/rootReducer");
				store.dispatch(resetStore());
			} else {
				errorHandler(e, true);
			}
			return Promise.reject(e);
		}
	}
	throw new Error("Unauthorized");
};
