import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { offerColors } from "../../styles/colors";
import { useNavigate } from "react-router-dom";
import { OfferDateStatus } from "./OfferDateStatus";
import { OfferStatus } from "./OfferStatus";
import { useDispatch } from "react-redux";
import { setOfferDetails } from "../../store/offersSlice";

export const Offer = ({ offer, disableOfferDetails }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const progressPercentages = useMemo(() => {
		const percentages = Math.round(
			(offer.amountSpent * 100) / offer.amountGoal,
		);

		return percentages > 100 ? "100%" : `${percentages}%`;
	}, [offer.amountGoal, offer.amountSpent]);

	const progressBarColor = useMemo(() => {
		switch (offer.status) {
			case "Active":
				return offerColors.active.progressBar;
			case "Expired":
				return offerColors.expired.progressBar;
			case "Paused":
				return offerColors.paused.progressBar;

			default:
				return null;
		}
	}, [offer.status]);

	const handlePressOffer = () => {
		if (disableOfferDetails) {
			return;
		}

		const onFinish = () => navigate("offer-details");
		dispatch(setOfferDetails({ offer, onFinish }));
	};

	return (
		<Box
			key={`offer-${offer.id}`}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
			boxShadow="0 4px 20px 0 rgba(0, 0, 0, 0.05)"
			sx={{
				"height": "100%",
				"borderRadius": "20px",
				"pt": "16px",
				"pb": "20px",
				"px": "24px",
				"backgroundColor": "white.main",
				":hover": {
					cursor: disableOfferDetails ? "auto" : "pointer",
				},
			}}
			onClick={handlePressOffer}
		>
			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				<Typography
					sx={{
						fontWeight: 500,
						fontSize: 16,
						lineHeight: "19px",
						color: "black.lightBlack",
						mb: "10px",
					}}
					style={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						display: "-webkit-box",
						WebkitBoxOrient: "vertical",
						whiteSpace: "normal",
						WebkitLineClamp: 1,
						wordWrap: "break-word",
					}}
					title={`${offer.name} Offer`}
				>
					{offer.name} Offer
				</Typography>
				<OfferStatus status={offer.status} />
			</Box>
			<Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					sx={{ mb: "8px" }}
				>
					<Typography
						sx={{ fontWeight: 500, fontSize: 20, lineHeight: "24px" }}
					>
						{offer.amountSpent.toLocaleString()}
						<Box
							sx={{
								fontSize: 12,
								lineHeight: "14px",
								fontWeight: 400,
								color: "black.dark",
							}}
							component="span"
						>
							{` SAR`}
						</Box>
					</Typography>
					<Typography
						sx={{ fontSize: 12, lineHeight: "14px", color: "gray.darkDark" }}
					>
						/{offer.amountGoal.toLocaleString()}
						<Box sx={{ fontSize: 8, lineHeight: "10px" }} component="span">
							{` SAR`}
						</Box>
					</Typography>
				</Box>
				<Box
					display="flex"
					alignItems="center"
					sx={{
						backgroundColor: "gray.light",
						height: "8px",
						width: "100%",
						borderRadius: 8,
						px: "2px",
						mb: "8px",
					}}
				>
					<Box
						sx={{
							height: "4px",
							backgroundColor: progressBarColor,
							width: progressPercentages,
							borderRadius: 4,
						}}
					/>
				</Box>
				<Box display="flex" justifyContent="space-between">
					<OfferDateStatus
						status={offer.status}
						validFrom={offer.validFrom}
						validTo={offer.validTo}
						expiredAt={offer.expiredAt}
					/>
					<Typography
						sx={{ fontSize: 12, lineHeight: "14px", color: "gray.darkDark" }}
					>{`${progressPercentages} of goal`}</Typography>
				</Box>
			</Box>
		</Box>
	);
};
