import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "../utils/errorHandling";
import {
	apiGetCreateBrandData,
	apiPostBrand,
	apiPostFirstBrand,
	apiGetUserInfo,
	apiUpdateBrand,
	apiPostCertificate,
	apiPostMIDandMCC,
	apiPostVatCertificate,
} from "../api";
import { setBalance, setUserDetails } from "./settingSlice";

const brandsInitialState = {
	brands: [],
	selectedBrand: null,
	selectedBrandID: null,
	categories: [],
	createBrandPdf: {
		certificate: null,
		vatCertificate: null,
	},
	isLoadingCreateBrandData: false,
	isLoadingBrand: false,
	isLoadingBrands: false,
	isLoadingCertificate: false,
	isLoadingAddMIDandMCC: false,
	isLoadingVatCertificate: false,
	allCompetitors: [],
};

// ========== THUNKS ==========

export const fetchCreateBrandData = createAsyncThunk(
	"brands/fetchCreateBrandData",
	async (_, { dispatch }) => {
		try {
			dispatch(setLoadingCreateBrandData(true));
			const response = await apiGetCreateBrandData();
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingCreateBrandData(false));
		}
	},
);

export const createFirstBrand = createAsyncThunk(
	"brands/createFirstBrand",
	async ({ userId, brand, onSuccess }, { dispatch }) => {
		try {
			dispatch(setLoadingBrand(true));
			await apiPostFirstBrand(brand, userId);
			onSuccess && onSuccess();
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingBrand(false));
		}
	},
);

export const addMIDandMCC = createAsyncThunk(
	"brands/addMIDandMCC",
	async ({ data, onSuccess }, { dispatch }) => {
		try {
			dispatch(setLoadingAddMIDandMCC(true));

			const response = await apiPostMIDandMCC(data);

			onSuccess && onSuccess();

			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingAddMIDandMCC(false));
		}
	},
);

export const createBrand = createAsyncThunk(
	"brands/createBrand",
	async ({ brand, onSuccess }, { dispatch }) => {
		try {
			dispatch(setLoadingBrand(true));
			const response = await apiPostBrand(brand);
			onSuccess && onSuccess();
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingBrand(false));
		}
	},
);

export const uploadCertificate = createAsyncThunk(
	"brands/uploadCertificate",
	async ({ certificate, signal, onUploadProgress }, { dispatch, getState }) => {
		try {
			dispatch(setLoadingCertificate(true));
			const state = getState();
			const userId =
				state.settings.userDetails?.id || state.auth.signUpData?.userId;

			const response = await apiPostCertificate(
				{ certificatePdf: certificate, userId },
				{ onUploadProgress, signal },
			);

			onUploadProgress && onUploadProgress(100);

			return response.data;
		} catch (e) {
			if (e.message !== "canceled") {
				errorHandler(e);
			}
		} finally {
			dispatch(setLoadingCertificate(false));
		}
	},
);

export const uploadVatCertificate = createAsyncThunk(
	"brands/uploadVatCertificate",
	async (
		{ vatCertificate, signal, onUploadProgress },
		{ dispatch, getState },
	) => {
		try {
			dispatch(setIsLoadingVatCertificate(true));
			const state = getState();
			const userId =
				state.settings.userDetails?.id || state.auth.signUpData?.userId;

			const response = await apiPostVatCertificate(
				{ vatCertificatePdf: vatCertificate, userId },
				{ onUploadProgress, signal },
			);
			onUploadProgress && onUploadProgress(100);

			return response.data;
		} catch (e) {
			if (e.message !== "canceled") {
				errorHandler(e);
			}
		} finally {
			dispatch(setIsLoadingVatCertificate(false));
		}
	},
);

export const fetchUserInfo = createAsyncThunk(
	"brands/fetchUserInfo",
	async (_, { dispatch }) => {
		try {
			dispatch(setLoadingBrands(true));
			const response = await apiGetUserInfo();

			// Pass the complete response data to setUserDetails
			dispatch(setUserDetails(response.data));

			// Return only the brands array for the brands reducer
			return response.data.companyBrands;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingBrands(false));
		}
	},
);

export const updateBrand = createAsyncThunk(
	"settings/updateBrand",
	async ({ updatedBrand, onUploadProgress }, { dispatch }) => {
		try {
			dispatch(setLoadingBrand(true));
			const response = await apiUpdateBrand(updatedBrand, onUploadProgress);
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingBrand(false));
		}
	},
);

// ========== SLICE ==========

const brandsSlice = createSlice({
	name: "brands",
	initialState: brandsInitialState,
	reducers: {
		setSelectedBrand: (state, { payload }) => {
			state.selectedBrand = payload;
			state.selectedBrandID = payload.id;
		},
		setLoadingBrand: (state, { payload }) => {
			state.isLoadingBrand = payload;
		},
		setLoadingBrands: (state, { payload }) => {
			state.isLoadingBrands = payload;
		},
		setLoadingCertificate: (state, { payload }) => {
			state.isLoadingCertificate = payload;
		},
		setLoadingAddMIDandMCC: (state, { payload }) => {
			state.isLoadingAddMIDandMCC = payload;
		},
		setIsLoadingVatCertificate: (state, { payload }) => {
			state.isLoadingVatCertificate = payload;
		},
		setLoadingCreateBrandData: (state, { payload }) => {
			state.isLoadingCreateBrandData = payload;
		},
		setFirstBrand: (state, { payload }) => {
			state.brands = [payload];
			state.selectedBrand = payload;
			state.selectedBrandID = payload.id;
		},
		resetCreateBrandPdf: (state) => {
			state.createBrandPdf.certificate = null;
			state.createBrandPdf.vatCertificate = null;
		},
		resetCreateBrandPdfCertificate: (state) => {
			state.createBrandPdf.certificate = null;
		},
		resetCreateBrandPdfVatCertificate: (state) => {
			state.createBrandPdf.vatCertificate = null;
		},
		resetBrands: (state) => {
			state.brands = [];
			state.isLoadingBrands = false;
			state.isLoadingCertificate = false;
			state.isLoadingVatCertificate = false;
			state.categories = [];
			state.selectedBrand = null;
			state.selectedBrandID = null;
			state.isLoadingBrand = false;
			state.isLoadingCreateBrandData = false;
			state.allCompetitors = [];
			state.createBrandPdf.certificate = null;
			state.createBrandPdf.vatCertificate = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
			state.selectedBrand = payload[0];
			state.selectedBrandID = payload[0].id;
			state.brands = payload;
		});
		builder.addCase(uploadCertificate.fulfilled, (state, { payload }) => {
			state.createBrandPdf.certificate = payload;
		});
		builder.addCase(uploadVatCertificate.fulfilled, (state, { payload }) => {
			state.createBrandPdf.vatCertificate = payload;
		});
		builder.addCase(fetchCreateBrandData.fulfilled, (state, { payload }) => {
			state.categories = payload.categories;
			state.allCompetitors = payload.brands;
		});
		builder.addCase(addMIDandMCC.fulfilled, (state, { payload }) => {
			const updatedBrand = payload;

			state.selectedBrand.MCCs = updatedBrand.MCCs;
			state.selectedBrand.MIDs = updatedBrand.MIDs;

			state.brands = state.brands.map((brand) => {
				if (brand.id === updatedBrand.id) {
					return updatedBrand;
				}

				return brand;
			});
		});
		builder.addCase(createBrand.fulfilled, (state, { payload }) => {
			state.selectedBrand = payload;
			state.selectedBrandID = payload.id;
			state.brands.push(payload);
			state.allCompetitors.push(payload);
		});
		builder.addCase(updateBrand.fulfilled, (state, { payload }) => {
			state.selectedBrand = { ...state.selectedBrand, ...payload };
			state.brands = state.brands.map((brand) =>
				brand.id === payload.id ? { ...brand, ...payload } : brand,
			);
		});
	},
});

export const brandsReducer = brandsSlice.reducer;

export const {
	setLoadingBrands,
	setLoadingBrand,
	setLoadingCertificate,
	setIsLoadingVatCertificate,
	setLoadingCreateBrandData,
	setLoadingAddMIDandMCC,
	setSelectedBrand,
	setFirstBrand,
	resetBrands,
	resetCreateBrandPdf,
	resetCreateBrandPdfCertificate,
	resetCreateBrandPdfVatCertificate,
} = brandsSlice.actions;
