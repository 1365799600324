import React, { useCallback, useRef, useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "components/Icon";
import { showSnackBar } from "store/uiSlice";
import { TextInputNew } from "components/TextInputNew";
import { FileUpload } from "components/FileUpload";
import { LoadingButton } from "@mui/lab";
import { ModalContainer } from "./ModalContainer";
import {
	createTransfer,
	resetReceiptFull,
	uploadReceipt,
	setTransactionReference,
} from "store/financialSlice";
import { validateForm } from "utils/validate";

const initialPaymentDetails = {
	amount: "",
	holderName: "",
};

const initialErrors = {
	amount: [],
	holderName: [],
};

export const BankTransfer = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Get initial reference from settings, fallback to financial slice
	const settingsTransactionReference = useSelector(
		(state) => state.settings.transactionReference,
	);
	const financialTransactionReference = useSelector(
		(state) => state.financial.transactionReference,
	);

	// Use settings reference by default, fallback to financial if available
	const currentTransactionReference =
		financialTransactionReference || settingsTransactionReference;

	// Get data from redux
	const bankAccount = useSelector((state) => state.settings.bankAccount);
	const receiptID = useSelector((state) => state.financial.receiptID);
	const isLoadingReceipt = useSelector(
		(state) => state.financial.isLoadingReceipt,
	);
	const uploadedReceipt = useSelector(
		(state) => state.financial.uploadedReceipt,
	);

	// Local state
	const [paymentDetails, setPaymentDetails] = useState(initialPaymentDetails);
	const [errors, setErrors] = useState(initialErrors);
	const [touched, setTouched] = useState({});
	const [receipt, setReceipt] = useState(uploadedReceipt || null);
	const [errorReceipt, setErrorReceipt] = useState("");
	const [uploadReceiptProgress, setUploadReceiptProgress] = useState(
		uploadedReceipt ? 100 : 0,
	);

	// For aborting file upload request
	const abortControllerRef = useRef(null);

	const handleClose = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	// Copy to clipboard helper
	const handleCopy = (value, type) => {
		navigator.clipboard.writeText(value);
		const messages = {
			accountName: "Account Name",
			accountNumber: "Account Number",
			ibanNumber: "IBAN",
			transactionReference: "Transaction Reference",
		};

		dispatch(
			showSnackBar({
				text: `${messages[type]} copied to clipboard`,
				severity: "info",
				duration: 1500,
			}),
		);
	};

	// Update Payment Details
	const handleChangePaymentDetails = useCallback((e) => {
		const { name, value } = e.target;
		setTouched((prev) => ({ ...prev, [name]: true }));
		setPaymentDetails((prev) => ({ ...prev, [name]: value }));
	}, []);

	const handleChangeReceipt = (newFile) => {
		if (!newFile) {
			setReceipt(null);
			setUploadReceiptProgress(0);
			dispatch(resetReceiptFull());
			return;
		}

		setReceipt(newFile);
		setUploadReceiptProgress(0);

		if (errorReceipt) {
			setErrorReceipt("");
		}

		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}
		const controller = new AbortController();
		abortControllerRef.current = controller;

		dispatch(
			uploadReceipt({
				receipt: newFile,
				transactionReference: currentTransactionReference,
				signal: controller.signal,
				onUploadProgress: (progress) => {
					setUploadReceiptProgress(progress);
				},
			}),
		);
	};

	// Validation
	const handleSubmitValidation = () => {
		const newErrors = validateForm(paymentDetails, "createBankTransfer");
		setErrors(newErrors);

		if (!receipt) {
			setErrorReceipt("Please upload the Bank Transfer Receipt");
		}

		setTouched({ amount: true, holderName: true });

		const noFieldErrors = Object.keys(newErrors).every(
			(key) => !newErrors[key].length,
		);
		return noFieldErrors && receipt;
	};

	const handleCreateTransfer = () => {
		const isFormValid = handleSubmitValidation();
		if (!isFormValid) return;

		const onSuccess = (response) => {
			// Transfer the reference to financial slice
			dispatch(setTransactionReference(response.transactionReference));
			dispatch(
				showSnackBar({
					text: "Transfer confirmation submitted successfully",
					severity: "success",
					duration: 3000,
				}),
			);
			handleClose();
		};

		dispatch(
			createTransfer({
				transfer: {
					receiptId: receiptID,
					amount: paymentDetails.amount,
					holderName: paymentDetails.holderName,
				},
				onSuccess,
			}),
		);
	};

	// Validate form on change
	const handleValidation = useCallback(
		(currentDetails) => {
			const newErrors = validateForm(currentDetails, "createBankTransfer");
			const filteredErrors = Object.keys(newErrors).reduce((obj, key) => {
				return {
					...obj,
					[key]: touched[key] ? newErrors[key] : [],
				};
			}, {});
			setErrors(filteredErrors);
		},
		[touched],
	);

	useEffect(() => {
		handleValidation(paymentDetails);
	}, [paymentDetails, handleValidation]);

	useEffect(() => {
		return () => {
			setPaymentDetails(initialPaymentDetails);
			setErrors(initialErrors);
			setTouched({});
		};
	}, []);

	useEffect(() => {
		if (uploadedReceipt) {
			setReceipt(uploadedReceipt);
			setUploadReceiptProgress(100);
		}
	}, [uploadedReceipt]);

	return (
		<ModalContainer
			handleClose={handleClose}
			title="Bank Transfer"
			description="Make a transfer to the bank account provided"
			headerStyle={{ mb: "24px" }}
		>
			{/* Bank Info */}
			<Box sx={{ mb: "24px" }}>
				{/* Bank Name */}
				<Typography
					sx={{
						fontFamily: "Inter",
						fontSize: "16px",
						lineHeight: "24px",
						fontWeight: 500,
						color: "#9A9A9A",
					}}
				>
					Bank Name
				</Typography>
				<Box sx={{ display: "flex", alignItems: "center", mb: "24px" }}>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontSize: "18px",
							lineHeight: "27px",
							fontWeight: 400,
							color: "#2C2C2C",
						}}
					>
						{bankAccount?.bankName}
					</Typography>
					<Icon
						name="anb"
						style={{ width: "28px", height: "28px", marginLeft: "8px" }}
					/>
				</Box>

				{/* Account Name */}
				<Typography
					sx={{
						fontFamily: "Inter",
						fontSize: "16px",
						lineHeight: "24px",
						fontWeight: 500,
						color: "#9A9A9A",
					}}
				>
					Account Name
				</Typography>
				<Box sx={{ display: "flex", alignItems: "center", mb: "24px" }}>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontSize: "18px",
							lineHeight: "27px",
							fontWeight: 400,
							color: "#2C2C2C",
						}}
					>
						{bankAccount?.accountName}
					</Typography>
					<Box
						component="span"
						onClick={() => handleCopy(bankAccount?.accountName, "accountName")}
						sx={{ cursor: "pointer", display: "inline-flex" }}
					>
						<Icon
							name="copy"
							style={{
								width: "20px",
								height: "20px",
								marginLeft: "8px",
							}}
						/>
					</Box>
				</Box>

				{/* Account Number */}
				<Typography
					sx={{
						fontFamily: "Inter",
						fontSize: "16px",
						lineHeight: "24px",
						fontWeight: 500,
						color: "#9A9A9A",
					}}
				>
					Account Number
				</Typography>
				<Box sx={{ display: "flex", alignItems: "center", mb: "24px" }}>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontSize: "18px",
							lineHeight: "27px",
							fontWeight: 400,
							color: "#2C2C2C",
						}}
					>
						{bankAccount?.accountNumber}
					</Typography>
					<Box
						component="span"
						onClick={() =>
							handleCopy(bankAccount?.accountNumber, "accountNumber")
						}
						sx={{ cursor: "pointer", display: "inline-flex" }}
					>
						<Icon
							name="copy"
							style={{
								width: "20px",
								height: "20px",
								marginLeft: "8px",
							}}
						/>
					</Box>
				</Box>

				{/* IBAN Number */}
				<Typography
					sx={{
						fontFamily: "Inter",
						fontSize: "16px",
						lineHeight: "24px",
						fontWeight: 500,
						color: "#9A9A9A",
					}}
				>
					IBAN Number
				</Typography>
				<Box sx={{ display: "flex", alignItems: "center", mb: "24px" }}>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontSize: "18px",
							lineHeight: "27px",
							fontWeight: 400,
							color: "#2C2C2C",
						}}
					>
						{bankAccount?.ibanNumber}
					</Typography>
					<Box
						component="span"
						onClick={() => handleCopy(bankAccount?.ibanNumber, "ibanNumber")}
						sx={{ cursor: "pointer", display: "inline-flex" }}
					>
						<Icon
							name="copy"
							style={{
								width: "20px",
								height: "20px",
								marginLeft: "8px",
							}}
						/>
					</Box>
				</Box>

				{/* Transaction Reference */}
				<Typography
					sx={{
						fontFamily: "Inter",
						fontSize: "16px",
						lineHeight: "24px",
						fontWeight: 500,
						color: "#9A9A9A",
					}}
				>
					Transaction Reference
				</Typography>
				<Box sx={{ display: "flex", alignItems: "center", mb: "16px" }}>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontSize: "18px",
							lineHeight: "27px",
							fontWeight: 400,
							color: "#2C2C2C",
						}}
					>
						{currentTransactionReference}
					</Typography>
					<Box
						component="span"
						onClick={() =>
							handleCopy(currentTransactionReference, "transactionReference")
						}
						sx={{ cursor: "pointer", display: "inline-flex" }}
					>
						<Icon
							name="copy"
							style={{
								width: "20px",
								height: "20px",
								marginLeft: "8px",
							}}
						/>
					</Box>
				</Box>
			</Box>

			{/* Confirm a transfer */}
			<Typography
				sx={{
					fontSize: "16px",
					lineHeight: "24px",
					fontWeight: 500,
					color: "#4D4D4D",
				}}
			>
				Confirm a transfer
			</Typography>
			<Typography
				sx={{
					fontSize: "12px",
					lineHeight: "16px",
					color: "#4D4D4D",
					mb: "16px",
				}}
			>
				Confirm your transfer so we can verify it and add the funds to your
				wallet
			</Typography>

			{/* Form Fields */}
			<Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
				<TextInputNew
					label="Transferred Amount"
					name="amount"
					value={paymentDetails.amount}
					onChange={handleChangePaymentDetails}
					containerStyle={{ width: "100%" }}
					units="SAR"
					errors={errors.amount}
				/>
				<TextInputNew
					label="Account Holder Name"
					name="holderName"
					value={paymentDetails.holderName}
					onChange={handleChangePaymentDetails}
					containerStyle={{ width: "100%" }}
					errors={errors.holderName}
				/>
			</Box>

			{/* File Upload */}
			<FileUpload
				label="Bank Transfer Receipt"
				uploadProgress={uploadReceiptProgress}
				file={receipt}
				onChangeFile={handleChangeReceipt}
				containerStyle={{ my: "24px", maxWidth: "100%" }}
				error={errorReceipt}
				abortControllerRef={abortControllerRef}
				pngAccept={true}
			/>

			{/* Info Text */}
			<Box sx={{ display: "flex", alignItems: "center", mb: "10px" }}>
				<Icon
					name="info"
					style={{ width: "16px", height: "16px", marginRight: "4px" }}
				/>
				<Typography
					sx={{
						fontSize: "14px",
						lineHeight: "20px",
						fontFamily: "inter",
						fontWeight: 500,
						color: "#61AEA0",
					}}
				>
					Only confirm if you have made transfer
				</Typography>
			</Box>

			{/* Confirm Button */}
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<LoadingButton
					variant="contained"
					onClick={handleCreateTransfer}
					loading={isLoadingReceipt}
					sx={{
						textTransform: "none",
						fontFamily: "Nunito",
						fontWeight: 600,
						height: "40px",
						width: "238px",
						borderRadius: "10px",
					}}
				>
					Confirm Payment
				</LoadingButton>
			</Box>
		</ModalContainer>
	);
};
