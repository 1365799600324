import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, duration, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import { Screen } from "../components/Screen";
import { Icon } from "components/Icon";
import { ADD_FUNDS_DETAILS } from "../constants";
import { showSnackBar } from "store/uiSlice";
import { TextInputNew } from "components/TextInputNew";
import { FileUpload } from "components/FileUpload";
import { LoadingButton } from "@mui/lab";
import {
	createTransfer,
	resetReceipt,
	uploadReceipt,
} from "store/financialSlice";
import { useSelector } from "react-redux";
import { validateForm } from "utils/validate";

const initialPaymendDetails = {
	amount: "",
	holderName: "",
};

const initialErrors = {
	amount: [],
	holderName: [],
};

export const FinancialOverview = () => {
	const dispatch = useDispatch();
	const balance = useSelector((state) => state.settings.balance);
	const receiptID = useSelector((state) => state.financial.receiptID);
	const isLoadingReceipt = useSelector(
		(state) => state.financial.isLoadingReceipt,
	);

	const [paymentDetails, setPaymentDetails] = useState(initialPaymendDetails);
	const [touched, setTouched] = useState({});
	const [errorReceipt, setErrorReceipt] = useState("");
	const [uploadReceiptProgress, setUploadReceiptProgress] = useState(0);
	const [receipt, setReceipt] = useState(null);
	const [errors, setErrors] = useState(initialErrors);

	const abortControllerRef = useRef(null);

	const handleChangeReceipt = (newReceipt) => {
		setReceipt(newReceipt);
		setUploadReceiptProgress(0);

		if (!newReceipt) {
			dispatch(resetReceipt());
			return;
		}

		if (errorReceipt) {
			setErrorReceipt("");
		}

		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		const abortController = new AbortController();
		abortControllerRef.current = abortController;

		dispatch(
			uploadReceipt({
				receipt: newReceipt,
				signal: abortController.signal,
				onUploadProgress: (progress) => {
					setUploadReceiptProgress(progress);
				},
			}),
		);
	};

	const handleChangePaymentDetails = useCallback(
		(e) => {
			const { name, value } = e.target;

			setTouched((prev) => ({
				...prev,
				[name]: true,
			}));

			setPaymentDetails((prev) => ({ ...prev, [name]: value }));
		},
		[setTouched, setPaymentDetails],
	);

	const handleCopy = (value) => {
		navigator.clipboard.writeText(value);
		dispatch(
			showSnackBar({
				text: "Value copied to clipboard",
				severity: "info",
				duration: 1500,
			}),
		);
	};

	const handleSubmitValidation = () => {
		const newErrors = validateForm(paymentDetails, "createBankTransfer");

		setErrors(newErrors);

		if (!receipt) {
			setErrorReceipt("Please upload the Bank Transfer Receipt");
		}

		const noErrors = Object.keys(newErrors).every(
			(key) => !newErrors[key].length,
		);

		if (noErrors && receipt) {
			return true;
		}

		return false;
	};

	const handleValidation = (paymentDetails) => {
		const newErrors = validateForm(paymentDetails, "createBankTransfer");

		// Include all fields in filteredErrors, but only include errors for fields that have been touched
		const filteredErrors = Object.keys(newErrors).reduce((obj, key) => {
			return {
				...obj,
				[key]: touched[key] ? newErrors[key] : [],
			};
		}, {});

		setErrors(filteredErrors);
	};

	useEffect(() => {
		handleValidation(paymentDetails);
	}, [paymentDetails]);

	const handleCreateTransfer = () => {
		const isFormValid = handleSubmitValidation();

		// if user pressed on continue we will show all errors
		setTouched({ domain: true, name: true, categoryId: true });

		if (!isFormValid) {
			return;
		}

		const onSuccess = () => {
			dispatch(
				showSnackBar({
					text: "You bank transfer was uploaded successfully. Please wait for approval!",
					severity: "success",
					duration: 5000,
				}),
			);

			setPaymentDetails(initialPaymendDetails);
			setReceipt(null);
		};

		dispatch(
			createTransfer({
				transfer: {
					receiptId: receiptID,
					amount: paymentDetails.amount,
					holderName: paymentDetails.holderName,
				},
				onSuccess,
			}),
		);
	};

	return (
		<Screen title={"Customer Persona & Behavior"}>
			<Box
				sx={{
					p: "24px",
					backgroundColor: "white.main",
					boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
					borderRadius: "20px",
					border: "1px solid #EFF0F6",
					mb: "24px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Inter",
						fontSize: "16px",
						lineHeight: "24px",
						fontWeight: "600",
						color: "#9A9A9A",
						mb: "3px",
					}}
				>
					Wallet Balance
				</Typography>
				<Box sx={{ display: "flex", alignItems: "flex-end" }}>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontWeight: "600",
							fontSize: "36px",
							lineHeight: "44px",
							color: "#2C2C2C",
							mr: "8px",
						}}
					>
						{balance?.toLocaleString()}
					</Typography>
					<Typography
						sx={{
							fontSize: "12px",
							lineHeight: "14px",
							color: "#232323",
						}}
					>
						SAR
					</Typography>
				</Box>
			</Box>
			<Box
				sx={{
					p: "24px",
					backgroundColor: "white.main",
					boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
					borderRadius: "20px",
					border: "1px solid #EFF0F6",
					mb: "24px",
				}}
			>
				<Typography
					sx={{
						fontSize: "16px",
						lineHeight: "19px",
						color: "#2C2C2C",
						fontWeight: "500",
						mb: "3px",
					}}
				>
					Add Funds
				</Typography>
				<Typography
					sx={{
						fontSize: "12px",
						lineHeight: "14px",
						color: "#2C2C2C",
						mb: "24px",
					}}
				>
					Make a transfer to the bank account provided
				</Typography>
				<Box sx={{ display: "flex", mb: "18px" }}>
					<Box sx={{ minWidth: "400px", mr: "24px" }}>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontWeight: "500",
								fontSize: "16px",
								lineHeight: "24px",
								color: "#9A9A9A",
								mb: "3px",
							}}
						>
							Bank Name
						</Typography>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontSize: "18px",
									lineHeight: "27px",
									color: "#2C2C2C",
								}}
							>
								{ADD_FUNDS_DETAILS.bankName}
							</Typography>
							<Icon
								name="anb"
								style={{
									width: "31px",
									height: "24px",
									marginLeft: "8px",
								}}
							/>
						</Box>
					</Box>
					<Box>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontWeight: "500",
								fontSize: "16px",
								lineHeight: "24px",
								color: "#9A9A9A",
								mb: "3px",
							}}
						>
							Account Name
						</Typography>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontSize: "18px",
									lineHeight: "27px",
									color: "#2C2C2C",
								}}
							>
								{ADD_FUNDS_DETAILS.accountName}
							</Typography>
							<Box
								sx={{
									marginLeft: "8px",
									cursor: "pointer",
									display: "flex",
								}}
								onClick={() => handleCopy(ADD_FUNDS_DETAILS.accountName)}
							>
								<Icon
									name="copy"
									style={{
										width: "24px",
										height: "24px",
									}}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box sx={{ display: "flex", mb: "21px" }}>
					<Box sx={{ minWidth: "400px", mr: "24px" }}>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontWeight: "500",
								fontSize: "16px",
								lineHeight: "24px",
								color: "#9A9A9A",
								mb: "3px",
							}}
						>
							Account Number
						</Typography>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontSize: "18px",
									lineHeight: "27px",
									color: "#2C2C2C",
								}}
							>
								{ADD_FUNDS_DETAILS.accountNumber}
							</Typography>
							<Box
								sx={{
									marginLeft: "8px",
									cursor: "pointer",
									display: "flex",
								}}
								onClick={() => handleCopy(ADD_FUNDS_DETAILS.accountNumber)}
							>
								<Icon
									name="copy"
									style={{
										width: "24px",
										height: "24px",
									}}
								/>
							</Box>
						</Box>
					</Box>
					<Box>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontWeight: "500",
								fontSize: "16px",
								lineHeight: "24px",
								color: "#9A9A9A",
								mb: "3px",
							}}
						>
							IBAN Number
						</Typography>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontSize: "18px",
									lineHeight: "27px",
									color: "#2C2C2C",
								}}
							>
								{ADD_FUNDS_DETAILS.ibanNumber}
							</Typography>
							<Box
								sx={{
									marginLeft: "8px",
									cursor: "pointer",
									display: "flex",
								}}
								onClick={() => handleCopy(ADD_FUNDS_DETAILS.ibanNumber)}
							>
								<Icon
									name="copy"
									style={{
										width: "24px",
										height: "24px",
									}}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
				<Typography
					sx={{
						fontSize: "16px",
						fontWeight: "500",
						lineHeight: "19px",
						color: "#4D4D4D",
						mb: "3px",
					}}
				>
					Confirm a transfer
				</Typography>
				<Typography
					sx={{
						fontSize: "12px",
						lineHeight: "14px",
						color: "#4D4D4D",
						mb: "16px",
					}}
				>
					Confirm your transfer so we can verify it and add the funds to your
					wallet
				</Typography>
				<Box sx={{ display: "flex", mb: "20px" }}>
					<TextInputNew
						label="Transferred Amount"
						name="amount"
						value={paymentDetails.amount}
						onChange={handleChangePaymentDetails}
						containerStyle={{ width: "400px", mr: "20px" }}
						units={"SAR"}
						errors={errors.amount}
					/>
					<TextInputNew
						label="Account Holder Name"
						name="holderName"
						value={paymentDetails.holderName}
						onChange={handleChangePaymentDetails}
						containerStyle={{ width: "400px" }}
						errors={errors.holderName}
					/>
				</Box>
				<FileUpload
					label="Bank Transfer Receipt"
					uploadProgress={uploadReceiptProgress}
					file={receipt}
					onChangeFile={handleChangeReceipt}
					containerStyle={{ mb: "24px", maxWidth: "820px" }}
					error={errorReceipt}
					abortControllerRef={abortControllerRef}
					pngAccept
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Icon
							name="info"
							style={{ width: "16px", height: "16px", marginRight: "4px" }}
						/>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontSize: "14px",
								fontWeight: "500",
								lineHeight: "17px",
								color: "#61AEA0",
								mr: "10px",
							}}
						>
							Only confirm if you have made transfer
						</Typography>
					</Box>
					<LoadingButton
						sx={{
							textTransform: "capitalize",
							height: "40px",
							width: "238px",
							borderRadius: "10px",
						}}
						loading={isLoadingReceipt}
						variant="contained"
						onClick={handleCreateTransfer}
					>
						Confirm Payment
					</LoadingButton>
				</Box>
			</Box>
		</Screen>
	);
};
