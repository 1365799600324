import React from "react";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { ordinalSuffixOf } from "../../utils/numbers";
import { offerColors } from "../../styles/colors";

export const OfferDateStatus = ({ status, validFrom, validTo, expiredAt }) => {
	const dateValidFrom = dayjs(validFrom);
	const dateValidTo = dayjs(validTo);
	const dateExpiredAt = dayjs(expiredAt);

	return (
		<>
			{status === "Expired" ? (
				<Typography
					sx={{
						fontSize: 12,
						lineHeight: "14px",
						color: offerColors.expired.primaryText,
					}}
				>
					{`Expired on ${
						ordinalSuffixOf(dateExpiredAt.format("D")) +
						" " +
						dateExpiredAt.format("MMM")
					}`}
				</Typography>
			) : null}
			{status === "Not-started" ? (
				<Typography
					sx={{
						fontSize: 12,
						lineHeight: "14px",
						color: offerColors.notStarted.primaryText,
					}}
				>
					{`Starts on ${
						ordinalSuffixOf(dateValidFrom.format("D")) +
						" " +
						dateValidFrom.format("MMM")
					}`}
				</Typography>
			) : null}
			{status === "Active" ? (
				<Typography
					sx={{
						fontSize: 12,
						lineHeight: "14px",
						color: offerColors.active.primaryText,
					}}
				>
					{`Valid until ${
						ordinalSuffixOf(dateValidTo.format("D")) +
						" " +
						dateValidTo.format("MMM")
					}`}
				</Typography>
			) : null}
			{status === "Paused" ? (
				<Typography
					sx={{
						fontSize: 12,
						lineHeight: "14px",
						color: offerColors.paused.primaryText,
					}}
				>
					{`Valid until ${
						ordinalSuffixOf(dateValidTo.format("D")) +
						" " +
						dateValidTo.format("MMM")
					}`}
				</Typography>
			) : null}
		</>
	);
};
