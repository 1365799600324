import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextInputNew } from "./TextInputNew";
import { StatusTag } from "./StatusTag";
import { showSnackBar } from "store/uiSlice";
import { updateUserDetails, updatePassword } from "store/settingSlice";
import { Icon } from "./Icon";
import { validateForm } from "utils/validate";

export const UserDetailsSettings = () => {
	const dispatch = useDispatch();
	const anyDispatch = /** @type {any} */ (dispatch);
	const user = useSelector(
		(state) => /** @type {any} */ (state).settings.userDetails,
	);
	const isUpdateUserLoading = useSelector(
		(state) => /** @type {any} */ (state).settings.updateUserLoading,
	);
	const isUpdatePasswordLoading = useSelector(
		(state) => /** @type {any} */ (state).settings.updatePasswordLoading,
	);

	const [isEditing, setIsEditing] = useState(false);
	const [hasChanged, setHasChanged] = useState(false);
	const [userDetails, setUserDetails] = useState({
		firstName: "",
		lastName: "",
		email: "",
		emailVerified: false,
		phoneNumber: "",
		phoneVerified: false,
	});
	const [initialUserDetails, setInitialUserDetails] = useState({
		firstName: "",
		lastName: "",
		email: "",
		emailVerified: false,
		phoneNumber: "",
		phoneVerified: false,
	});

	const [formErrors, setFormErrors] = useState({
		firstName: [],
		lastName: [],
	});

	const [isEditingPassword, setIsEditingPassword] = useState(false);
	const [passwordDetails, setPasswordDetails] = useState({
		oldPassword: "",
		password: "",
		confirmPassword: "",
	});
	const [passwordErrors, setPasswordErrors] = useState({
		oldPassword: "",
		password: "",
		confirmPassword: "",
	});
	const [passwordValidationErrors, setPasswordValidationErrors] = useState({
		password: [],
		confirmPassword: [],
	});

	const [touched, setTouched] = useState({});

	const [hasUserDetailsErrors, setHasUserDetailsErrors] = useState(false);
	const [hasPasswordErrors, setHasPasswordErrors] = useState(false);

	const [passwordFieldsTouched, setPasswordFieldsTouched] = useState({
		oldPassword: false,
		password: false,
		confirmPassword: false,
	});

	useEffect(() => {
		const hasErrors =
			(formErrors.firstName && formErrors.firstName.length > 0) ||
			(formErrors.lastName && formErrors.lastName.length > 0);

		setHasUserDetailsErrors(hasErrors);
	}, [formErrors]);

	useEffect(() => {
		const hasErrors =
			(passwordValidationErrors.password &&
				passwordValidationErrors.password.length > 0) ||
			(passwordValidationErrors.confirmPassword &&
				passwordValidationErrors.confirmPassword.length > 0);

		setHasPasswordErrors(hasErrors);
	}, [passwordValidationErrors]);

	useEffect(() => {
		if (user) {
			const initialDetails = {
				firstName: user.firstName || "",
				lastName: user.lastName || "",
				email: user.emailDetails?.email || "",
				emailVerified: user.emailDetails?.verified || false,
				phoneNumber: user.phoneDetails?.phone || "",
				phoneVerified: user.phoneDetails?.verified || false,
			};
			setUserDetails(initialDetails);
			setInitialUserDetails(initialDetails);
			setIsEditing(false);
			setHasChanged(false);
			setTouched({});
			setFormErrors({
				firstName: [],
				lastName: [],
			});
		}
	}, [user]);

	const validateUserDetails = (data) => {
		const validationData = {
			firstName: data.firstName,
			lastName: data.lastName,
		};

		return validateForm(validationData, "userDetails");
	};

	const validatePasswordDetails = (data) => {
		return validateForm(data, "passwordChange");
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		if (name === "email" || name === "phoneNumber") {
			setUserDetails((prev) => ({
				...prev,
				[name]: value,
			}));
			setHasChanged(true);
			return;
		}

		setTouched((prev) => ({
			...prev,
			[name]: true,
		}));

		setUserDetails((prev) => ({
			...prev,
			[name]: value,
		}));
		setHasChanged(true);

		const updatedDetails = { ...userDetails, [name]: value };
		const errors = validateUserDetails(updatedDetails);

		setFormErrors((prevErrors) => ({
			...prevErrors,
			[name]: touched[name] ? errors[name] || [] : [],
		}));
	};

	const handlePasswordChange = (event) => {
		const { name, value } = event.target;

		setPasswordFieldsTouched((prev) => ({
			...prev,
			[name]: true,
		}));

		const updatedPasswordDetails = {
			...passwordDetails,
			[name]: value,
		};
		setPasswordDetails(updatedPasswordDetails);

		setPasswordErrors((prev) => ({
			...prev,
			[name]: "",
		}));

		if (
			name === "password" &&
			value === updatedPasswordDetails.oldPassword &&
			value !== ""
		) {
			setPasswordValidationErrors((prev) => ({
				...prev,
				password: ["New password must be different from current password"],
			}));

			setHasPasswordErrors(true);
			return;
		}

		const validationErrors = validatePasswordDetails(updatedPasswordDetails);

		const filteredErrors = {
			oldPassword: passwordFieldsTouched.oldPassword
				? validationErrors.oldPassword || []
				: [],
			password: passwordFieldsTouched.password
				? validationErrors.password || []
				: [],
			confirmPassword: passwordFieldsTouched.confirmPassword
				? validationErrors.confirmPassword || []
				: [],
		};

		setPasswordValidationErrors(filteredErrors);

		const hasErrors =
			(filteredErrors.oldPassword && filteredErrors.oldPassword.length > 0) ||
			(filteredErrors.password && filteredErrors.password.length > 0) ||
			(filteredErrors.confirmPassword &&
				filteredErrors.confirmPassword.length > 0);

		setHasPasswordErrors(hasErrors);
	};

	const handleEditMode = () => {
		setIsEditing(true);
		setTouched({});
		setFormErrors({
			firstName: [],
			lastName: [],
		});
	};

	const handleEditPassword = () => {
		setIsEditingPassword(true);
		setPasswordDetails({
			oldPassword: "",
			password: "",
			confirmPassword: "",
		});
		setPasswordErrors({
			oldPassword: "",
			password: "",
			confirmPassword: "",
		});
		setPasswordValidationErrors({
			password: [],
			confirmPassword: [],
		});
		setPasswordFieldsTouched({
			oldPassword: false,
			password: false,
			confirmPassword: false,
		});
	};

	const handleCancel = () => {
		if (initialUserDetails) {
			setUserDetails(initialUserDetails);
		}
		setIsEditing(false);
		setHasChanged(false);
		setTouched({});
		setFormErrors({
			firstName: [],
			lastName: [],
		});
	};

	const handleCancelPassword = () => {
		setIsEditingPassword(false);
		setPasswordDetails({
			oldPassword: "",
			password: "",
			confirmPassword: "",
		});
		setPasswordErrors({
			oldPassword: "",
			password: "",
			confirmPassword: "",
		});
		setPasswordValidationErrors({
			password: [],
			confirmPassword: [],
		});
		setPasswordFieldsTouched({
			oldPassword: false,
			password: false,
			confirmPassword: false,
		});
	};

	const handleSave = async () => {
		try {
			const errors = validateUserDetails(userDetails);

			const allTouched = {
				firstName: true,
				lastName: true,
			};
			setTouched(allTouched);

			setFormErrors(errors);

			const hasErrors =
				(errors.firstName && errors.firstName.length > 0) ||
				(errors.lastName && errors.lastName.length > 0);

			if (hasErrors) {
				dispatch(
					showSnackBar({
						text: "Please fix the errors before saving.",
						severity: "error",
						duration: 3000,
					}),
				);
				return;
			}

			const updatedFields = {};
			for (const key in userDetails) {
				if (userDetails[key] !== initialUserDetails[key]) {
					updatedFields[key] = userDetails[key];
				}
			}

			// @ts-ignore
			const result = await dispatch(updateUserDetails(updatedFields));

			if (result.error) {
				throw new Error(result.error);
			}

			// Update the initialUserDetails and userDetails with the response
			const updatedUserInfo = result.payload;
			setUserDetails((prev) => ({
				...prev,
				firstName: updatedUserInfo.firstName || prev.firstName,
				lastName: updatedUserInfo.lastName || prev.lastName,
			}));

			setInitialUserDetails((prev) => ({
				...prev,
				firstName: updatedUserInfo.firstName || prev.firstName,
				lastName: updatedUserInfo.lastName || prev.lastName,
			}));

			dispatch(
				showSnackBar({
					text: "User details updated successfully!",
					severity: "success",
					duration: 3000,
				}),
			);

			setIsEditing(false);
			setHasChanged(false);
			setTouched({});
			setFormErrors({
				firstName: [],
				lastName: [],
			});
		} catch (error) {
			console.error("Failed to update user details:", error);
			dispatch(
				showSnackBar({
					text: "Failed to update user details. Please try again.",
					severity: "error",
					duration: 3000,
				}),
			);
		}
	};

	const handleSavePassword = async () => {
		setPasswordFieldsTouched({
			oldPassword: true,
			password: true,
			confirmPassword: true,
		});

		const validationErrors = validatePasswordDetails(passwordDetails);
		setPasswordValidationErrors(validationErrors);

		const hasErrors =
			(validationErrors.oldPassword &&
				validationErrors.oldPassword.length > 0) ||
			(validationErrors.password && validationErrors.password.length > 0) ||
			(validationErrors.confirmPassword &&
				validationErrors.confirmPassword.length > 0);

		if (hasErrors) {
			dispatch(
				showSnackBar({
					text: "Please fix the validation errors before saving.",
					severity: "error",
					duration: 3000,
				}),
			);
			return;
		}

		try {
			const result = await anyDispatch(
				updatePassword({
					userId: user.id,
					password: passwordDetails.password,
					oldPassword: passwordDetails.oldPassword,
				}),
			);

			if (result.payload?.newPassword) {
				dispatch(
					showSnackBar({
						text: "Password updated successfully!",
						severity: "success",
						duration: 3000,
					}),
				);

				setIsEditingPassword(false);
				setPasswordDetails({
					oldPassword: "",
					password: "",
					confirmPassword: "",
				});
				setPasswordValidationErrors({
					password: [],
					confirmPassword: [],
				});
			}
		} catch (error) {
			if (error.isPasswordError) {
				setPasswordErrors((prev) => ({
					...prev,
					[error.field || "oldPassword"]: error.message,
				}));

				dispatch(
					showSnackBar({
						text: error.message || "Incorrect password. Please try again.",
						severity: "error",
						duration: 3000,
					}),
				);
				return;
			}

			dispatch(
				showSnackBar({
					text: "Failed to update password. Please try again.",
					severity: "error",
					duration: 3000,
				}),
			);
		}
	};

	const renderTextInput = (label, name, disabled, additionalProps = {}) => {
		if (isEditing) {
			return (
				<Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "5px",
							mb: "3px",
						}}
					>
						<Typography
							sx={{
								fontSize: "16px",
								fontWeight: "500",
								lineHeight: "19px",
								color: "#4D4D4D",
							}}
						>
							{label}
						</Typography>
						{name === "phoneNumber" && (
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Icon name="info" style={{ fontSize: 16, color: "#61AEA0" }} />
								<Typography
									sx={{
										fontSize: "14px",
										lineHeight: "17px",
										fontWeight: "500",
										color: "#61AEA0",
										marginLeft: "4px",
									}}
								>
									Contact us to change your phone number
								</Typography>
							</Box>
						)}
					</Box>
					<TextInputNew
						label={null}
						name={name}
						value={
							name === "password" ? "************" : userDetails[name] || ""
						}
						placeholder={label}
						onChange={handleInputChange}
						disabled={disabled || name === "email" || name === "phoneNumber"}
						containerStyle={{ width: "30%" }}
						errors={formErrors[name] || []}
						{...additionalProps}
					/>
				</Box>
			);
		}
		return (
			<Box>
				<Typography
					sx={{
						fontSize: "16px",
						fontWeight: "500",
						fontFamily: "Inter",
						lineHeight: "19px",
						color: "#9A9A9A",
						mb: "5px",
						display: "flex",
						alignItems: "center",
					}}
				>
					{label}
					{!isEditing && (name === "email" || name === "phoneNumber") && (
						<Box component="span" sx={{ ml: "5px" }}>
							<StatusTag
								statusId={
									userDetails[
										name === "email" ? "emailVerified" : "phoneVerified"
									]
										? 3
										: 2
								}
							/>
						</Box>
					)}
				</Typography>
				<Typography
					sx={{
						fontSize: "18px",
						fontFamily: "Inter",
						lineHeight: "27px",
						color: "#2C2C2C",
					}}
				>
					{name === "password" ? "************" : userDetails[name] || ""}
				</Typography>
			</Box>
		);
	};

	const renderPasswordSection = () => {
		if (!isEditingPassword) {
			return (
				<Box>
					<Typography
						sx={{
							fontSize: "16px",
							fontWeight: "500",
							fontFamily: "Inter",
							lineHeight: "19px",
							color: "#9A9A9A",
							mb: "5px",
						}}
					>
						Password
					</Typography>
					<Typography
						sx={{
							fontSize: "18px",
							fontFamily: "Inter",
							lineHeight: "27px",
							color: "#2C2C2C",
						}}
					>
						************
					</Typography>
				</Box>
			);
		}

		return (
			<Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
				<TextInputNew
					label="Current Password"
					name="oldPassword"
					value={passwordDetails.oldPassword}
					onChange={handlePasswordChange}
					password
					error={!!passwordErrors.oldPassword}
					helperText={passwordErrors.oldPassword}
					containerStyle={{ width: "30%" }}
				/>
				<Box sx={{ display: "flex", gap: "24px" }}>
					<TextInputNew
						label="New Password"
						name="password"
						value={passwordDetails.password}
						onChange={handlePasswordChange}
						password
						errors={passwordValidationErrors.password || []}
						containerStyle={{ width: "30%" }}
					/>
					<TextInputNew
						label="Confirm New Password"
						name="confirmPassword"
						value={passwordDetails.confirmPassword}
						onChange={handlePasswordChange}
						password
						errors={passwordValidationErrors.confirmPassword || []}
						containerStyle={{ width: "30%" }}
					/>
				</Box>
			</Box>
		);
	};

	return (
		<Box
			sx={{
				display: "grid",
				gridTemplateRows: "auto auto",
				gap: "24px",
			}}
		>
			{/* Personal Information Section */}
			<Box
				sx={{
					p: "24px",
					border: "1px solid #EFF0F6",
					borderRadius: "20px",
					backgroundColor: "white.main",
					boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
				}}
			>
				<Typography
					sx={{
						fontWeight: "500",
						fontSize: "16px",
						lineHeight: "19px",
						color: "#2C2C2C",
						marginBottom: "5px",
					}}
				>
					Personal Information
				</Typography>
				<Typography
					sx={{
						fontSize: "12px",
						lineHeight: "14px",
						color: "#2C2C2C",
					}}
				>
					View and edit your personal information
				</Typography>
				<Box
					sx={{
						display: "grid",
						gap: "12px",
						marginTop: "24px",
						marginBottom: "24px",
					}}
				>
					{renderTextInput("First Name", "firstName", !isEditing)}
					{renderTextInput("Last Name", "lastName", !isEditing)}
					{renderTextInput("Business Email", "email", true)}
					{renderTextInput("Phone Number", "phoneNumber", true)}
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "24px",
					}}
				>
					{!isEditing ? (
						<Button
							variant="contained"
							color="primary"
							onClick={handleEditMode}
							sx={{
								width: "104px",
								height: "40px",
								borderRadius: "10px",
								textTransform: "none",
							}}
						>
							Edit
						</Button>
					) : (
						<>
							<Button
								variant="outlined"
								onClick={handleCancel}
								sx={{
									width: "104px",
									height: "40px",
									borderRadius: "10px",
									mr: "10px",
									textTransform: "none",
								}}
							>
								Cancel
							</Button>
							<LoadingButton
								loading={isUpdateUserLoading}
								variant="contained"
								color="primary"
								onClick={handleSave}
								disabled={
									!hasChanged || isUpdateUserLoading || hasUserDetailsErrors
								}
								sx={{
									width: "188px",
									height: "40px",
									borderRadius: "10px",
									textTransform: "none",
								}}
							>
								Save changes
							</LoadingButton>
						</>
					)}
				</Box>
			</Box>

			{/* Security Section */}
			<Box
				sx={{
					p: "24px",
					border: "1px solid #EFF0F6",
					borderRadius: "20px",
					backgroundColor: "white.main",
					boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
					height: "fit-content",
				}}
			>
				<Typography
					sx={{
						fontWeight: "500",
						fontSize: "16px",
						lineHeight: "19px",
						color: "#2C2C2C",
						marginBottom: "5px",
					}}
				>
					Security
				</Typography>
				<Typography
					sx={{
						fontSize: "12px",
						lineHeight: "14px",
						color: "#2C2C2C",
						marginBottom: "24px",
					}}
				>
					Manage your account password
				</Typography>
				{renderPasswordSection()}
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "24px",
					}}
				>
					{!isEditingPassword ? (
						<Button
							variant="contained"
							color="primary"
							onClick={handleEditPassword}
							sx={{
								width: "104px",
								height: "40px",
								borderRadius: "10px",
								textTransform: "none",
							}}
						>
							Edit
						</Button>
					) : (
						<>
							<Button
								variant="outlined"
								onClick={handleCancelPassword}
								sx={{
									width: "104px",
									height: "40px",
									borderRadius: "10px",
									mr: "10px",
									textTransform: "none",
								}}
							>
								Cancel
							</Button>
							<LoadingButton
								loading={isUpdatePasswordLoading}
								variant="contained"
								color="primary"
								onClick={handleSavePassword}
								disabled={
									isUpdatePasswordLoading ||
									hasPasswordErrors ||
									!passwordDetails.oldPassword ||
									!passwordDetails.password ||
									!passwordDetails.confirmPassword
								}
								sx={{
									width: "188px",
									height: "40px",
									borderRadius: "10px",
									textTransform: "none",
								}}
							>
								Save changes
							</LoadingButton>
						</>
					)}
				</Box>
			</Box>
		</Box>
	);
};
