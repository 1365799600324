import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	apiUpdateUserDetails,
	apiGetBillings,
	apiUpdateBillings,
	apiUpdatePassword,
} from "../api";
import { errorHandler } from "../utils/errorHandling";
import * as Sentry from "@sentry/react";

const settingsInitialState = {
	userDetails: null,
	balance: 0,
	billings: null,
	loading: false,
	updateBillingLoading: false,
	bankAccount: null,
	transactionReference: null,
	updateUserLoading: false,
	updatePasswordLoading: false,
};

export const updateUserDetails = createAsyncThunk(
	"settings/updateUserDetails",
	async (userDetails, { rejectWithValue }) => {
		try {
			const response = await apiUpdateUserDetails(userDetails);
			return response.data;
		} catch (e) {
			errorHandler(e);
			return rejectWithValue(e);
		}
	},
);

export const fetchBillings = createAsyncThunk(
	"settings/fetchBillings",
	async (_, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiGetBillings();
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoading(false));
		}
	},
);

/** @typedef {{ billings: any, onSuccess?: () => void, onFinish?: () => void }} BillingsPayload */

export const updateBillings = createAsyncThunk(
	"settings/updateBillings",
	/** @param {BillingsPayload} payload */
	async (payload, { dispatch }) => {
		try {
			dispatch(setUpdateBillingLoading(true));
			const response = await apiUpdateBillings(payload.billings);
			payload.onSuccess?.();
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			payload.onFinish?.();
			dispatch(setUpdateBillingLoading(false));
		}
	},
);

/** @typedef {{ userId: string, password: string, oldPassword: string }} PasswordUpdateData */

export const updatePassword = createAsyncThunk(
	"settings/updatePassword",
	/** @param {PasswordUpdateData} data */
	async (data, { rejectWithValue }) => {
		try {
			const response = await apiUpdatePassword(data.userId, {
				password: data.password,
				oldPassword: data.oldPassword,
			});
			if (response.data && response.data.newPassword) {
				return response.data;
			} else {
				return rejectWithValue("Incorrect password. Please try again");
			}
		} catch (e) {
			if (e.response?.data?.password) {
				return rejectWithValue({
					message: e.response.data.password[0],
					field: "oldPassword",
				});
			}

			if (
				e.response &&
				(e.response.status === 401 || e.response.status === 403)
			) {
				return rejectWithValue({
					message: "Incorrect password. Please try again",
					field: "oldPassword",
				});
			}

			console.error("Password update error:", e);
			return rejectWithValue(e.message || "Failed to update password");
		}
	},
);

const settingsSlice = createSlice({
	name: "settings",
	initialState: settingsInitialState,
	reducers: {
		setLoading: (state, { payload }) => {
			state.loading = payload;
		},
		setUpdateBillingLoading: (state, { payload }) => {
			state.updateBillingLoading = payload;
		},
		resetSettings: (state) => {
			state.userDetails = null;
			state.billings = null;
			state.balance = 0;
			state.loading = false;
			state.updateBillingLoading = false;
		},
		setUserDetails: (state, { payload }) => {
			state.userDetails = payload.user;
			state.balance = payload.balance;
			state.bankAccount = payload.bankAccount;
			state.transactionReference = payload.transactionReference;

			Sentry.setUser({
				id: payload.user.id,
				email: payload.user.emailDetails.email,
			});
		},
		setBalance: (state, { payload }) => {
			state.balance = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchBillings.fulfilled, (state, { payload }) => {
			state.billings = payload;
		});
		builder.addCase(fetchBillings.rejected, (state) => {
			state.billings = null;
		});
		builder.addCase(updateBillings.fulfilled, (state, { payload }) => {
			state.billings = payload;
		});
		builder.addCase(updateUserDetails.pending, (state) => {
			state.updateUserLoading = true;
		});
		builder.addCase(updateUserDetails.fulfilled, (state, { payload }) => {
			// Only update the fields that were returned in the response
			state.userDetails = {
				...state.userDetails,
				firstName: payload.firstName ?? state.userDetails.firstName,
				lastName: payload.lastName ?? state.userDetails.lastName,
			};
			state.updateUserLoading = false;
		});
		builder.addCase(updateUserDetails.rejected, (state) => {
			state.updateUserLoading = false;
		});
		builder.addCase(updatePassword.pending, (state) => {
			state.updatePasswordLoading = true;
		});
		builder.addCase(updatePassword.fulfilled, (state) => {
			state.updatePasswordLoading = false;
		});
		builder.addCase(updatePassword.rejected, (state) => {
			state.updatePasswordLoading = false;
		});
	},
});

export const settingsReducer = settingsSlice.reducer;
export const {
	setLoading,
	setUpdateBillingLoading,
	resetSettings,
	setUserDetails,
	setBalance,
} = settingsSlice.actions;
