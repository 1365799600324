import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiPostReceipt, apiPostTransfer } from "../api";
import { errorHandler } from "../utils/errorHandling";

const financialInitialState = {
	receipt: null,
	receiptID: null,
	isLoadingReceipt: false,
	isLoadingTransfer: false,
	uploadedReceipt: null,
	transactionReference: null,
};

export const uploadReceipt = createAsyncThunk(
	"financial/uploadReceipt",
	async (
		{ receipt, signal, onUploadProgress, transactionReference },
		{ dispatch },
	) => {
		try {
			dispatch(setIsLoadingReceipt(true));
			// Store the receipt file in state before upload
			dispatch(setReceipt(receipt));

			const response = await apiPostReceipt(
				{ receipt, transactionReference },
				{
					onUploadProgress,
					signal,
				},
			);

			onUploadProgress(100);

			return response.data;
		} catch (e) {
			if (e.message !== "canceled") {
				errorHandler(e);
			}
		} finally {
			dispatch(setIsLoadingReceipt(false));
		}
	},
);

export const createTransfer = createAsyncThunk(
	"financial/createTransfer",
	async ({ transfer, onSuccess }, { dispatch }) => {
		try {
			dispatch(setIsLoadingTransfer(true));

			const response = await apiPostTransfer(transfer);

			onSuccess && onSuccess(response.data);

			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setIsLoadingTransfer(false));
		}
	},
);

const financialSlice = createSlice({
	name: "settings",
	initialState: financialInitialState,
	reducers: {
		resetReceipt: (state) => {
			state.receiptID = null;
		},
		setIsLoadingReceipt: (state, { payload }) => {
			state.isLoadingReceipt = payload;
		},
		setIsLoadingTransfer: (state, { payload }) => {
			state.isLoadingTransfer = payload;
		},
		resetFinancial: (state) => {
			state.isLoadingReceipt = false;
			state.receipt = null;
		},
		setTransactionReference: (state, { payload }) => {
			state.transactionReference = payload;
		},
		resetReceiptFull: (state) => {
			state.receipt = null;
			state.receiptID = null;
			state.uploadedReceipt = null;
		},
		setReceipt: (state, { payload }) => {
			state.receipt = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(uploadReceipt.fulfilled, (state, { payload }) => {
			state.receiptID = payload;
			state.uploadedReceipt = state.receipt;
		});
		builder.addCase(createTransfer.fulfilled, (state, { payload }) => {
			state.transactionReference = payload.transactionReference;
			state.receipt = null;
			state.receiptID = null;
			state.uploadedReceipt = null;
		});
	},
});

export const financialReducer = financialSlice.reducer;
export const {
	setIsLoadingReceipt,
	resetReceipt,
	resetFinancial,
	setIsLoadingTransfer,
	setTransactionReference,
	resetReceiptFull,
	setReceipt,
} = financialSlice.actions;
