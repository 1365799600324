import React from "react";
import { Box, Typography } from "@mui/material";
import { Icon } from "./Icon";
import { useSelector } from "react-redux";

export const Balance = ({ containerStyle }) => {
	const balance = useSelector((state) => state.settings.balance);

	return (
		<Box
			sx={{
				position: "relative",
				display: "flex",
				alignItems: "center",
				height: "46px",
				width: "194px",
				px: "16px",
				backgroundColor: "white.main",
				borderRadius: "10px",
				boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
				...containerStyle,
			}}
		>
			<Icon
				name="walletBalance"
				style={{
					marginRight: "10px",
					width: "24px",
					height: "24px",
				}}
			/>
			<Box>
				<Typography
					sx={{
						fontSize: "10px",
						lineHeight: "12px",
						color: "black.main",
						mb: "3px",
					}}
				>
					Wallet Balance
				</Typography>
				<Box sx={{ display: "flex", alignItems: "flex-end" }}>
					<Typography
						sx={{
							fontSize: "12px",
							lineHeight: "14px",
							fontWeight: "500",
							color: "black.main",
							mr: "3px",
						}}
					>
						{balance?.toLocaleString()}
					</Typography>
					<Typography
						sx={{
							fontSize: "10px",
							lineHeight: "13px",
							fontWeight: "300",
							color: "black.main",
						}}
					>
						SAR
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};
