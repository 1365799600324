import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RadioButtonCircle } from "./RadioButtonCircle";
import { setSelectedBrand, updateBrand } from "../store/brandsSlice";
import { Icon } from "./Icon";
import { useNavigate } from "react-router-dom";
import { TextInputNew } from "./TextInputNew";
import { StatusTag } from "./StatusTag";
import styled from "@emotion/styled";
import LoadingButton from "@mui/lab/LoadingButton";
import { showSnackBar } from "store/uiSlice";
import { FileUpload } from "./FileUpload";
import { colors } from "styles/colors";
import { LogoUploader } from "./LogoUploader";
import * as Sentry from "@sentry/react";

const BrandRadioButton = ({ brand, selected, onClick }) => {
	return (
		<Box
			sx={{
				"display": "flex",
				"justifyContent": "space-between",
				"alignItems": "center",
				"height": "65px",
				"backgroundColor": selected ? "#EEF6F4" : "white.main",
				"borderRadius": "10px",
				"pl": "16px",
				"pr": "25px",
				"mb": "24px",
				"&:hover": {
					cursor: "pointer",
				},
			}}
			onClick={onClick}
		>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<img
					src={brand.picture}
					style={{
						width: "48px",
						height: "48px",
						borderRadius: "100px",
						border: "1px solid #EFF0F6",
						marginRight: "26px",
						background: colors.white,
					}}
				/>
				<Typography
					sx={{
						fontWeight: "500",
						fontSize: "16px",
						lineHeight: "19px",
						color: selected ? "#2C2C2C" : "#4D4D4D",
					}}
				>
					{brand.name}
				</Typography>
			</Box>
			<RadioButtonCircle selected={selected} />
		</Box>
	);
};

const CustomButton = styled(Button)(({ theme }) => ({
	"width": "30%",
	"marginTop": "16px",
	"fontFamily": "Inter",
	"fontSize": 18,
	"fontWeight": 400,
	"lineHeight": "27px",
	"color": "#232323",
	"height": 59,
	"borderRadius": 10,
	"border": "1.5px solid rgba(0, 0, 0, 0.26)",
	"backgroundColor": "#2C2C2C0D",
	"textTransform": "none",
	"justifyContent": "flex-start",
	"padding": theme.spacing(1.5, 2),
	"display": "flex",
	"alignItems": "center",
	"&:hover": {
		backgroundColor: "#2C2C2C0D",
		borderColor: "#D9D9D9",
		borderWidth: "1.5px",
	},
	".button-text": {
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		display: "inline-block",
		maxWidth: "calc(100% - 16px)",
	},
}));

export const BrandSettings = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { brands, selectedBrand } = useSelector((state) => state.brands);
	const isUpdateBrandLoading = useSelector(
		(state) => state.brands.isLoadingBrand,
	);
	const [isEditing, setIsEditing] = useState(false);
	const [hasChanged, setHasChanged] = useState({
		domain: false,
		certificate: false,
		picture: false,
	});
	const [domain, setDomain] = useState(
		selectedBrand?.domain?.name ? selectedBrand.domain : null,
	);
	const [certificatePdf, setCertificatePdf] = useState(
		selectedBrand?.certificate?.name ? selectedBrand.certificate : null,
	);
	const [brandPicture, setBrandPicture] = useState(selectedBrand?.picture);
	const [uploadProgress, setUploadProgress] = useState(
		selectedBrand?.certificate?.name ? 100 : 0,
	);
	const user = useSelector((state) => state.settings.userDetails);

	const handleDomainChange = (event) => {
		setDomain((prev) => {
			return {
				...prev,
				name: event.target.value,
			};
		});
		setHasChanged((prev) => {
			return {
				...prev,
				domain: true,
			};
		});
	};

	const handleCertificatePdfChange = (newCertificatePdf) => {
		setCertificatePdf(newCertificatePdf);
		setUploadProgress(0);
		setHasChanged((prev) => {
			return {
				...prev,
				certificate: true,
			};
		});
	};

	const handlePictureChange = (imageFile) => {
		setBrandPicture(imageFile);
		setHasChanged((prev) => {
			return {
				...prev,
				picture: true,
			};
		});
	};

	const handleEditMode = () => {
		setIsEditing(true);
	};

	const handleCancel = () => {
		setDomain(selectedBrand?.domain?.name ? selectedBrand.domain : null);
		setCertificatePdf(
			selectedBrand?.certificate?.name ? selectedBrand.certificate : null,
		);
		setBrandPicture(selectedBrand?.picture);
		setIsEditing(false);
		setHasChanged({ domain: false, certificate: false, picture: false });
		setUploadProgress(selectedBrand?.certificate?.name ? 100 : 0);
	};

	const handleSave = async () => {
		const formData = new FormData();
		formData.append("id", selectedBrand.id);
		if (domain && hasChanged.domain) {
			formData.append("domain", domain.name);
			formData.append("domainApprovalId", domain.approvalId);
		}
		if (certificatePdf && hasChanged.certificate) {
			formData.append("certificatePdf", certificatePdf);
		}
		if (brandPicture && brandPicture.file && hasChanged.picture) {
			formData.append("picture", brandPicture.file);
		}

		try {
			await dispatch(
				updateBrand({
					updatedBrand: formData,
					onUploadProgress: (progressEvent) => {
						const progress = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total,
						);
						setUploadProgress(progress);
					},
				}),
			).unwrap();
			setIsEditing(false);
			setHasChanged({ domain: false, certificate: false, picture: false });
			dispatch(
				showSnackBar({
					text: `${selectedBrand.name} updated successfully!`,
					severity: "success",
				}),
			);
		} catch (error) {
			Sentry.captureException(error, {
				tags: {
					area: "BrandSettings",
					action: "updateBrand",
				},
			});
		}
	};

	const handleChangeSelectedBrand = (brand) => {
		dispatch(setSelectedBrand(brand));
	};

	const handleGoAddBrand = () => {
		navigate("create-brand");
	};

	const handleShowPDF = () => {
		if (selectedBrand?.certificate.link?.includes("pdf")) {
			window.open(selectedBrand?.certificate.link, "_blank");
		} else {
			alert("No file uploaded");
		}
	};

	const handleButtonDisableStatus = () => {
		const isAnyChanged =
			hasChanged.domain || hasChanged.certificate || hasChanged.picture;
		const isDomainValid = domain && domain.name;
		const isCertificateValid = certificatePdf;
		const isPictureValid = brandPicture;

		return !(
			isAnyChanged &&
			isDomainValid &&
			isCertificateValid &&
			isPictureValid
		);
	};

	useEffect(() => {
		setDomain(selectedBrand?.domain?.name ? selectedBrand.domain : null);
		setCertificatePdf(
			selectedBrand?.certificate?.name ? selectedBrand.certificate : null,
		);
		setBrandPicture(selectedBrand?.picture);
		setIsEditing(false);
		setHasChanged({
			domain: false,
			certificate: false,
			picture: false,
		});
		setUploadProgress(selectedBrand?.certificate?.name ? 100 : 0);
	}, [selectedBrand]);

	return (
		<Box
			sx={{
				display: "grid",
				gridTemplateRow: "1fr 1fr",
				gap: "24px",
			}}
		>
			<Box
				sx={{
					p: "24px",
					border: "1px solid #EFF0F6",
					borderRadius: "20px",
					backgroundColor: "white.main",
					boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
				}}
			>
				<Box
					sx={{
						width: "100px",
						height: "100px",
						borderRadius: "100px",
						border: "1px solid #EFF0F6",
						marginBottom: "16px",
						position: "relative",
					}}
				>
					{isEditing ? (
						<LogoUploader
							logo={brandPicture}
							setLogo={handlePictureChange}
							edit={isEditing}
							containerStyle={{
								width: "100%",
								height: "100%",
							}}
						/>
					) : (
						<img
							src={brandPicture}
							style={{
								height: "100%",
								width: "100%",
								borderRadius: "50%",
								border: "1px solid #EFF0F6",
								backgroundColor: colors.white,
								boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
							}}
						/>
					)}
				</Box>
				<Typography
					sx={{
						fontWeight: "500",
						fontSize: "20px",
						lineHeight: "24px",
						color: "#2C2C2C",
					}}
				>
					{selectedBrand?.name}
				</Typography>
				<Box
					sx={{
						marginTop: "24px",
					}}
				>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
					>
						<Typography
							sx={{
								fontSize: "16px",
								lineHeight: "19px",
								fontWeight: "500",
								color: "#4D4D4D",
								marginRight: "16px",
							}}
						>
							Commercial Registration Certificate
						</Typography>
						{!isEditing ? (
							<StatusTag statusId={certificatePdf?.approvalId} />
						) : (
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Icon name="info" style={{ fontSize: 16, color: "#61AEA0" }} />
								<Typography
									sx={{
										fontSize: "14px",
										lineHeight: "17px",
										fontWeight: "500",
										color: "#61AEA0",
										marginLeft: "4px",
									}}
								>
									PDF file will be uploaded when you save changes!
								</Typography>
							</Box>
						)}
					</Grid>

					<Typography
						sx={{
							fontSize: "12px",
							lineHeight: "14px",
							fontWeight: "400",
							color: "#4D4D4D",
							marginTop: "5px",
							display: "flex",
							alignItems: "center",
							gap: "8px",
						}}
					>
						upload your CR to get verified
					</Typography>
					{isEditing ? (
						<Box
							sx={{
								width: "30%",
								marginTop: "16px",
							}}
						>
							<FileUpload
								uploadProgress={uploadProgress}
								file={certificatePdf}
								onChangeFile={handleCertificatePdfChange}
							/>
						</Box>
					) : (
						<CustomButton
							variant="outlined"
							onClick={handleShowPDF}
							disabled={!certificatePdf?.link?.includes("pdf")}
						>
							<Icon
								name="pdf"
								style={{ width: 16, height: 16, marginRight: 8, flexShrink: 0 }}
							/>
							<span className="button-text">
								{certificatePdf?.name || "No file uploaded"}
							</span>
						</CustomButton>
					)}
				</Box>
				<Box
					sx={{
						marginTop: "24px",
					}}
				>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
					>
						<Typography
							sx={{
								fontSize: "16px",
								fontWeight: "500",
								lineHeight: "19px",
								color: "#4D4D4D",
								marginRight: "16px",
							}}
						>
							Brand Domain
						</Typography>
						{!isEditing && <StatusTag statusId={domain?.approvalId} />}
					</Grid>
					<Typography
						sx={{
							fontSize: "12px",
							fontWeight: "400",
							lineHeight: "14px",
							color: "#4D4D4D",
							marginTop: "5px",
						}}
					>
						Add your brand domain link here
					</Typography>
					<TextInputNew
						value={domain?.name || ""}
						placeholder={domain?.name || "Please enter domain name"}
						name="domain"
						onChange={handleDomainChange}
						disabled={!isEditing}
						containerStyle={{
							width: "30%",
							marginTop: "16px",
						}}
					/>
				</Box>
				<Box
					sx={{
						marginTop: "24px",
					}}
				>
					<Typography
						sx={{
							fontWeight: "500",
							fontSize: "16px",
							lineHeight: "19px",
							color: " #4D4D4D",
							marginBottom: "3px",
						}}
					>
						Account Portfolio
					</Typography>
					<Typography
						sx={{
							fontSize: "12px",
							lineHeight: "14px",
							color: " #4D4D4D",
							marginBottom: "16px",
						}}
					>
						view your account type plan
					</Typography>
					<TextInputNew
						disabled
						value={user?.accountPortfolio}
						containerStyle={{
							width: "30%",
						}}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "24px",
					}}
				>
					{!isEditing ? (
						<Button
							variant="contained"
							color="primary"
							onClick={handleEditMode}
							sx={{
								width: "104px",
								height: "40px",
								borderRadius: "10px",
								textTransform: "none",
							}}
						>
							Edit
						</Button>
					) : (
						<>
							<Button
								variant="outlined"
								onClick={handleCancel}
								sx={{
									width: "104px",
									height: "40px",
									borderRadius: "10px",
									mr: "10px",
									textTransform: "none",
								}}
							>
								Cancel
							</Button>
							<LoadingButton
								loading={isUpdateBrandLoading}
								variant="contained"
								color="primary"
								onClick={handleSave}
								disabled={handleButtonDisableStatus()}
								sx={{
									width: "188px",
									height: "40px",
									borderRadius: "10px",
									textTransform: "none",
								}}
							>
								Save changes
							</LoadingButton>
						</>
					)}
				</Box>
			</Box>
			<Box
				sx={{
					p: "24px",
					border: "1px solid #EFF0F6",
					borderRadius: "20px",
					backgroundColor: "white.main",
					boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
				}}
			>
				<Typography
					sx={{
						fontWeight: "500",
						fontSize: "16px",
						lineHeight: "19px",
						color: "#4D4D4D",
						mb: "3px",
					}}
				>
					Brands
				</Typography>
				<Typography
					sx={{
						fontSize: "12px",
						lineHeight: "14px",
						color: "#4D4D4D",
						mb: "24px",
					}}
				>
					switch brand or add a new one
				</Typography>
				{brands.map((brand) => (
					<BrandRadioButton
						key={`switch-brand-${brand.id}`}
						brand={brand}
						selected={selectedBrand?.id === brand.id}
						onClick={() => handleChangeSelectedBrand(brand)}
					/>
				))}
				<Box
					sx={{
						"display": "flex",
						"alignItems": "center",
						"height": "65px",
						"borderRadius": "10px",
						"&:hover": { cursor: "pointer" },
						"px": "16px",
					}}
					onClick={handleGoAddBrand}
				>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						sx={{
							width: "48px",
							height: "48px",
							borderRadius: "50%",
							backgroundColor: "#e5eaee",
							boxShadow: "0 10px 20px 0 rgba(17, 110, 95, 0.05)",
							mr: "26px",
						}}
					>
						<Icon name="plusOffer" style={{ fontSize: 16 }} />
					</Box>
					<Typography
						sx={{
							fontWeight: "500",
							fontSize: "16px",
							lineHeight: "19px",
							color: "#4D4D4D",
						}}
					>
						Add Brand
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};
