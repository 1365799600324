import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setEditOffer as setEditOfferStore } from "../store/offersSlice";
import dayjs from "dayjs";
import { MultiSelect } from "../components/MultiSelect";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../components/TextInput";
import { ModalContainer } from "./ModalContainer";
import { DatePicker } from "../components/DatePicker";
import { TimePicker } from "../components/TimePicker";
import { validateOffer } from "../utils/validate";
import { Checkbox } from "../components/Checkbox";

export const EditOfferGeneral = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const catalogs = useSelector(({ offers }) => offers.catalogs);
	const offerEditStore = useSelector(({ offers }) => offers.offerEdit);
	const [editOffer, setEditOffer] = useState(offerEditStore);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		setEditOffer(offerEditStore);
	}, [offerEditStore]);

	const handleClose = () => {
		navigate("/offers");
		setErrors({});
	};

	const handleContinue = (e) => {
		e.preventDefault();

		const formErrors = validateOffer(editOffer);

		if (!editOffer.limitAmountEnabled) {
			delete formErrors.limitAmountMax;
			delete formErrors.limitAmountMin;
		}

		if (!editOffer.limitUsesEnabled) {
			delete formErrors.limitUses;
		}
		setErrors(formErrors);

		if (Object.keys(formErrors).length > 0) {
			return;
		}

		dispatch(setEditOfferStore(editOffer));
		navigate("/offers/edit-offer-client-of");
	};

	const onChangeEditOffer = useCallback(
		(e) => {
			const { name, value } = e.target;
			setEditOffer((prev) => ({ ...prev, [name]: value }));

			if (name in errors) {
				const updatedErrors = { ...errors };
				delete updatedErrors[name];

				setErrors(updatedErrors);
			}
		},
		[editOffer],
	);

	const handleChangeDate = (newDate, field) => {
		setEditOffer((prev) => ({ ...prev, [field]: newDate }));
	};

	if (!editOffer) {
		return null;
	}

	return (
		<ModalContainer title={`Edit Offer`} handleClose={handleClose}>
			<Box component="form" onSubmit={handleContinue}>
				<TextInput
					value={editOffer.name}
					onChange={onChangeEditOffer}
					label="Name"
					name="name"
					error={!!errors.name}
					helperText={errors.name?.[0]}
					type={"text"}
					units={""}
					disabled={false}
					placeholder="Enter offer name"
				/>
				<Box display="flex" columnGap={"24px"} sx={{ mb: "24px" }}>
					<DatePicker
						value={editOffer.validFrom}
						onChange={(date) => handleChangeDate(date, "validFrom")}
						name="validFrom"
						label="Start Date"
					/>
					<DatePicker
						defaultValue={dayjs()}
						value={editOffer.validTo}
						onChange={(date) => handleChangeDate(date, "validTo")}
						name="validTo"
						label="End Date"
					/>
				</Box>
				<Box display="flex" columnGap={"24px"} sx={{ mb: "6px" }}>
					<TimePicker
						value={editOffer.timeFrom}
						onChange={(time) =>
							setEditOffer((prev) => ({ ...prev, timeFrom: time }))
						}
						name="timeFrom"
						label="Start Time"
						disabled={!!editOffer.allTime}
					/>
					<TimePicker
						value={editOffer.timeTo}
						onChange={(time) =>
							setEditOffer((prev) => ({ ...prev, timeTo: time }))
						}
						name="timeTo"
						label="End Time"
						disabled={!!editOffer.allTime}
					/>
				</Box>
				<Checkbox
					disableRipple
					checked={editOffer.allTime}
					onChange={() =>
						onChangeEditOffer({
							target: {
								name: "allTime",
								value: !editOffer.allTime,
							},
						})
					}
					name="24/7"
					containerStyle={{ ml: "5px", mb: "22px" }}
				/>
				<TextInput
					value={editOffer.budget}
					onChange={onChangeEditOffer}
					type="text"
					label="Budget goal"
					name="budget"
					variant="outlined"
					fullWidth
					error={!!errors.budget}
					units={"SAR"}
					helperText={errors.budget?.[0]}
					placeholder="Enter Budget"
				/>
				<Box display="flex" columnGap={"24px"}>
					<TextInput
						value={editOffer.discount}
						onChange={onChangeEditOffer}
						type="text"
						label="Discount"
						name="discount"
						error={!!errors.discount}
						helperText={errors.discount?.[0]}
						units={"%"}
						placeholder="Enter Amount"
						containerStyle={{ width: "100%" }}
					/>
					<TextInput
						value={editOffer.returnPeriod}
						onChange={onChangeEditOffer}
						type="text"
						label="Return Period"
						name="returnPeriod"
						error={!!errors.returnPeriod}
						helperText={errors.returnPeriod?.[0]}
						units={"Day"}
						placeholder={"Enter No.of days"}
						containerStyle={{ width: "100%" }}
					/>
				</Box>
				<Typography sx={{ mb: "6px" }}>Limit Per-User</Typography>
				<Box display="flex" columnGap={"24px"}>
					<Box sx={{ width: "100%" }}>
						<Checkbox
							disableRipple
							checked={editOffer.limitAmountEnabled}
							onChange={() =>
								setEditOffer((prev) => ({
									...prev,
									limitAmountEnabled: !prev.limitAmountEnabled,
								}))
							}
							gap={5}
							iconStyle={{ marginRight: "5px" }}
							name="Amount"
							containerStyle={{ ml: "5px", mb: "8px" }}
						/>
						<Box sx={{ display: "flex", justifyContent: "space-between" }}>
							<TextInput
								value={editOffer.limitAmountMin}
								onChange={onChangeEditOffer}
								name="limitAmountMin"
								type="text"
								error={!!errors.limitAmountMin}
								helperText={errors.limitAmountMin?.[0]}
								disabled={!editOffer.limitAmountEnabled}
								units={"SAR"}
								placeholder={"Enter Amount"}
								containerStyle={{ mr: "10px" }}
							/>
							<TextInput
								value={editOffer.limitAmountMax}
								onChange={onChangeEditOffer}
								name="limitAmountMax"
								type="text"
								error={!!errors.limitAmountMax}
								helperText={errors.limitAmountMax?.[0]}
								disabled={!editOffer.limitAmountEnabled}
								units={"SAR"}
								placeholder={"Enter Amount"}
							/>
						</Box>
					</Box>
					<Box sx={{ width: "100%" }}>
						<Checkbox
							disableRipple
							checked={editOffer.limitUsesEnabled}
							onChange={() =>
								setEditOffer((prev) => ({
									...prev,
									limitUsesEnabled: !prev.limitUsesEnabled,
								}))
							}
							gap={5}
							name="Uses"
							containerStyle={{ ml: "5px", mb: "8px" }}
						/>
						<TextInput
							value={editOffer.limitUses}
							onChange={onChangeEditOffer}
							name="limitUses"
							type="text"
							error={!!errors.limitUses}
							helperText={errors.limitUses?.[0]}
							units={""}
							disabled={!editOffer.limitUsesEnabled}
							placeholder={"Enter No. of uses"}
						/>
					</Box>
				</Box>
				<Box display="flex" columnGap={"24px"}>
					<MultiSelect
						allOption
						label="Age"
						name={"Select-Age"}
						data={catalogs.ages}
						selectedItems={editOffer.age}
						setSelectedItems={(newAge) =>
							onChangeEditOffer({
								target: { name: "age", value: newAge },
							})
						}
						placeholder="Select Type"
						error={!!errors.age}
						helperText={errors.age?.[0]}
						containerStyle={{ width: "100%" }}
					/>
					<MultiSelect
						allOption
						label="Gender"
						name={"Select-Gender"}
						data={catalogs.genders}
						selectedItems={editOffer.gender}
						setSelectedItems={(newGender) =>
							onChangeEditOffer({
								target: { name: "gender", value: newGender },
							})
						}
						placeholder="Select Gender"
						error={!!errors.gender}
						helperText={errors.gender?.[0]}
						containerStyle={{ width: "100%" }}
					/>
				</Box>
				<MultiSelect
					allOption
					label="Type"
					name={"Select-Type"}
					data={catalogs.typeOfClient}
					selectedItems={editOffer.typeOfClient}
					setSelectedItems={(newTypeOfClient) =>
						onChangeEditOffer({
							target: { name: "typeOfClient", value: newTypeOfClient },
						})
					}
					placeholder="Select Type"
					error={!!errors.typeOfClient}
					helperText={errors.typeOfClient?.[0]}
					containerStyle={{ width: "100%" }}
				/>
				<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
					<Button
						sx={{
							height: "45px",
							mt: "8px",
							borderRadius: "10px",
							width: "238px",
						}}
						variant="contained"
						fullWidth
						type="submit"
					>
						<Typography
							sx={{
								fontFamily: "Nunito",
								fontWeight: "600",
								fontSize: 16,
								textTransform: "capitalize",
							}}
						>
							Continue
						</Typography>
					</Button>
				</Box>
			</Box>
		</ModalContainer>
	);
};
