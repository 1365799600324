import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "../utils/errorHandling";
import {
	apiGetCreateBrandData,
	apiPostBrand,
	apiPostFirstBrand,
	apiGetUserInfo,
	apiUpdateBrand,
	apiPostCertificate,
} from "../api";
import { setBalance, setUserDetails } from "./settingSlice";

const brandsInitialState = {
	brands: [],
	selectedBrand: null,
	categories: [],
	createBrandPdf: {
		certificate: null,
	},
	isLoadingCreateBrandData: false,
	isLoadingBrand: false,
	isLoadingBrands: false,
	isLoadingCertificate: false,
	allCompetitors: [],
};

export const fetchCreateBrandData = createAsyncThunk(
	"brands/fetchCreateBrandData",
	async (_, { dispatch }) => {
		try {
			dispatch(setLoadingCreateBrandData(true));
			const response = await apiGetCreateBrandData();
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingCreateBrandData(false));
		}
	},
);

export const createFirstBrand = createAsyncThunk(
	"brands/createFirstBrand",
	async ({ userId, brand, onSuccess }, { dispatch }) => {
		try {
			dispatch(setLoadingBrand(true));

			await apiPostFirstBrand(brand, userId);

			onSuccess && onSuccess();
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingBrand(false));
		}
	},
);

export const createBrand = createAsyncThunk(
	"brands/createBrand",
	async ({ brand, onSuccess }, { dispatch }) => {
		try {
			dispatch(setLoadingBrand(true));

			const response = await apiPostBrand(brand);

			onSuccess && onSuccess();

			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingBrand(false));
		}
	},
);

export const uploadCertificate = createAsyncThunk(
	"brands/uploadCertificate",
	async ({ certificate, signal, onUploadProgress }, { dispatch }) => {
		try {
			dispatch(setIsLoadingCertificate(true));

			const response = await apiPostCertificate(
				{ certificatePdf: certificate },
				{ onUploadProgress, signal },
			);

			onUploadProgress(100);

			return response.data;
		} catch (e) {
			if (e.message !== "canceled") {
				errorHandler(e);
			}
		} finally {
			dispatch(setIsLoadingCertificate(false));
		}
	},
);

export const fetchUserInfo = createAsyncThunk(
	"brands/fetchUserInfo",
	async (_, { dispatch }) => {
		try {
			dispatch(setLoadingBrands(true));
			const response = await apiGetUserInfo();
			const { companyBrands, user, balance } = response.data;
			dispatch(setUserDetails(user));
			dispatch(setBalance(balance));
			return companyBrands;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingBrands(false));
		}
	},
);

export const updateBrand = createAsyncThunk(
	"settings/updateBrand",
	async ({ updatedBrand, onUploadProgress }, { dispatch }) => {
		try {
			dispatch(setLoadingBrand(true));
			const response = await apiUpdateBrand(updatedBrand, onUploadProgress);
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingBrand(false));
		}
	},
);

const brandsSlice = createSlice({
	name: "brands",
	initialState: brandsInitialState,
	reducers: {
		setSelectedBrand: (state, { payload }) => {
			state.selectedBrand = payload;
		},
		setLoadingBrand: (state, { payload }) => {
			state.isLoadingBrand = payload;
		},
		setLoadingBrands: (state, { payload }) => {
			state.isLoadingBrands = payload;
		},
		setIsLoadingCertificate: (state, { payload }) => {
			state.isLoadingCertificate = payload;
		},
		setLoadingCreateBrandData: (state, { payload }) => {
			state.isLoadingCreateBrandData = payload;
		},
		setFirstBrand: (state, { payload }) => {
			state.brands = [payload];
			state.selectedBrand = payload;
		},
		resetCreateBrandPdf: (state) => {
			state.createBrandPdf.certificate = null;
		},
		resetCreateBrandPdfCertificate: (state) => {
			state.createBrandPdf.certificate = null;
		},
		resetBrands: (state) => {
			state.brands = [];
			state.isLoadingBrands = false;
			state.isLoadingCertificate = false;
			state.categories = [];
			state.selectedBrand = null;
			state.isLoadingBrand = false;
			state.isLoadingCreateBrandData = false;
			state.allCompetitors = [];
			state.createBrandPdf.certificate = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
			state.selectedBrand = payload[0];
			state.brands = payload;
		});
		builder.addCase(uploadCertificate.fulfilled, (state, { payload }) => {
			state.createBrandPdf.certificate = payload;
		});
		builder.addCase(fetchCreateBrandData.fulfilled, (state, { payload }) => {
			state.categories = payload.categories;
			state.allCompetitors = payload.brands;
		});
		builder.addCase(createBrand.fulfilled, (state, { payload }) => {
			state.selectedBrand = payload;
			state.brands.push(payload);
			state.allCompetitors.push(payload);
		});
		builder.addCase(updateBrand.fulfilled, (state, { payload }) => {
			state.selectedBrand = { ...state.selectedBrand, ...payload };
			state.brands = state.brands.map((brand) =>
				brand.id === payload.id ? { ...brand, ...payload } : brand,
			);
		});
	},
});

export const brandsReducer = brandsSlice.reducer;
export const {
	setLoadingBrands,
	setLoadingBrand,
	setIsLoadingCertificate,
	setLoadingCreateBrandData,
	setSelectedBrand,
	setFirstBrand,
	resetBrands,
	resetCreateBrandPdf,
	resetCreateBrandPdfCertificate,
} = brandsSlice.actions;
