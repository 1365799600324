import React, { useState, useEffect } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { Icon } from "components/Icon";
import { useNavigate, useLocation } from "react-router-dom";

// Payment method definitions
const PAYMENT_METHODS = [
	{
		id: "creditCard",
		label: "Credit Card",
		icons: "creditCards",
		comingSoon: true,
		route: "/settings/credit-card",
	},
	{
		id: "paypal",
		label: "Paypal",
		icons: "paypal",
		comingSoon: true,
		route: "/settings/paypal",
	},
	{
		id: "bankTransfer",
		label: "Bank Transfer",
		icons: "bank",
		comingSoon: false,
		route: "/settings/bank-transfer",
	},
];

export const FinancialOverview = () => {
	const navigate = useNavigate();
	const location = useLocation();

	// 1) settings slice states
	const balance = useSelector((state) => state.settings.balance);

	// Get transaction reference from both slices
	const settingsTransactionReference = useSelector(
		(state) => state.settings.transactionReference,
	);
	const financialTransactionReference = useSelector(
		(state) => state.financial.transactionReference,
	);

	// Use financial reference if available, otherwise use settings
	const currentTransactionReference =
		financialTransactionReference || settingsTransactionReference;

	// 2) Payment method selection
	const [selectedMethod, setSelectedMethod] = useState(null);

	useEffect(() => {
		setSelectedMethod(null);
	}, [location.pathname]);

	// -----------------------------
	// HELPERS & EVENT HANDLERS
	// -----------------------------

	const handleSelectMethod = (methodId) => {
		const method = PAYMENT_METHODS.find((m) => m.id === methodId);
		if (!method) return;

		if (method.comingSoon && method.id !== "bankTransfer") {
			return;
		}

		setSelectedMethod(methodId);

		if (method.id === "bankTransfer") {
			navigate(method.route);
		}
	};

	// Render Payment Method Button
	const renderPaymentMethodButton = (method) => {
		const isSelected = selectedMethod === method.id;
		const isPaymentDisabled = !currentTransactionReference;
		const isDisabled = method.comingSoon;
		const finalDisabled = isPaymentDisabled || isDisabled;

		return (
			<Tooltip
				key={method.id}
				title={
					isPaymentDisabled
						? "Loading payment details..."
						: isDisabled
							? "Coming soon"
							: ""
				}
				placement="top"
				disableHoverListener={!finalDisabled}
			>
				<Box
					onClick={() => !finalDisabled && handleSelectMethod(method.id)}
					sx={{
						"display": "flex",
						"alignItems": "center",
						"justifyContent": "space-between",
						"padding": "16px",
						"borderRadius": "10px",
						"border": isSelected ? "1px solid #146E60" : "1px solid #E2E8F0",
						"backgroundColor": isSelected ? "#EEF6F4" : "#FFFFFF",
						"cursor": finalDisabled ? "not-allowed" : "pointer",
						"transition": "all 0.2s ease-in-out",
						"opacity": finalDisabled ? 0.5 : 1,
						"&:hover": {
							backgroundColor:
								!finalDisabled && !isSelected ? "#F9FAFB" : undefined,
						},
						"width": "auto",
						"height": "64px",
					}}
				>
					<Typography
						sx={{
							fontSize: "18px",
							lineHeight: "27px",
							fontWeight: 400,
							color: isSelected && !finalDisabled ? "#232323" : "#4D4D4D",
							marginRight: "8px",
						}}
					>
						{method.label}
					</Typography>
					<Icon
						key={method.icons}
						name={method.icons}
						style={{
							height: "24px",
							fill: isSelected ? "#232323" : "#4D4D4D",
						}}
					/>
				</Box>
			</Tooltip>
		);
	};

	return (
		<Box sx={{ display: "grid", gridTemplateRows: "auto 1fr", gap: "24px" }}>
			{/* 1) Wallet Balance Box */}
			<Box
				sx={{
					p: "24px",
					border: "1px solid #EFF0F6",
					borderRadius: "20px",
					backgroundColor: "white.main",
					boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Inter",
						fontSize: "16px",
						lineHeight: "24px",
						fontWeight: "600",
						color: "#9A9A9A",
						mb: "3px",
					}}
				>
					Wallet Balance
				</Typography>
				<Box sx={{ display: "flex", alignItems: "flex-end" }}>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontWeight: "600",
							fontSize: "36px",
							lineHeight: "44px",
							color: "#2C2C2C",
							mr: "8px",
						}}
					>
						{balance?.toLocaleString() || 0}
					</Typography>
					<Typography
						sx={{
							fontSize: "12px",
							lineHeight: "14px",
							color: "#232323",
						}}
					>
						SAR
					</Typography>
				</Box>
			</Box>

			{/* 2) Add Funds Box */}
			<Box
				sx={{
					p: "24px",
					border: "1px solid #EFF0F6",
					borderRadius: "20px",
					backgroundColor: "white.main",
					boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
				}}
			>
				<Typography
					sx={{
						fontSize: "16px",
						lineHeight: "19px",
						color: "#2C2C2C",
						fontWeight: "500",
						mb: "3px",
					}}
				>
					Add Funds
				</Typography>
				<Typography
					sx={{
						fontSize: "12px",
						lineHeight: "14px",
						color: "#4D4D4D",
						mb: "24px",
					}}
				>
					Select a payment method
				</Typography>

				<Box
					sx={{
						display: "grid",
						gridTemplateColumns: "repeat(2, 1fr)",
						gap: "20px",
					}}
				>
					{PAYMENT_METHODS.map(renderPaymentMethodButton)}
				</Box>
			</Box>
		</Box>
	);
};
