import React, { useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { offerColors } from "../styles/colors";
import { OfferStatus } from "../components/Offer/OfferStatus";
import { OfferDateStatus } from "../components/Offer/OfferDateStatus";
import { useDispatch, useSelector } from "react-redux";
import {
	changeStatusOffer,
	fetchOfferClone,
	fetchOfferEdit,
} from "../store/offersSlice";
import { LoadingButton } from "@mui/lab";
import { showSnackBar } from "../store/uiSlice";
import { ModalContainer } from "./ModalContainer";

export const OfferDetails = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const offer = useSelector(({ offers }) => offers.offerDetails);
	const catalogs = useSelector(({ offers }) => offers.catalogs);
	const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
	const [loadingOfferEdit, setLoadingOfferEdit] = useState(false);
	const [loadingOfferClone, setLoadingOfferClone] = useState(false);

	const handleClose = () => {
		navigate(-1);
	};

	const shownEditOfferButton = useMemo(
		() =>
			(offer.status === "Paused" || offer.status === "Not-started") &&
			catalogs?.ages?.length &&
			catalogs?.typeOfClient?.length &&
			catalogs?.clientOf?.length,
		[catalogs, offer.status],
	);

	const handlePressOfferEdit = () => {
		setLoadingOfferEdit(true);

		const onFinish = () => setLoadingOfferEdit(false);
		const onSuccess = () => {
			navigate("/offers/edit-offer-general");
		};

		dispatch(fetchOfferEdit({ offerId: offer.id, onFinish, onSuccess }));
	};

	const handlePressOfferClone = () => {
		setLoadingOfferClone(true);

		const onFinish = () => setLoadingOfferClone(false);
		const onSuccess = () => {
			navigate("/offers/create-offer-general");
		};

		dispatch(fetchOfferClone({ offerId: offer.id, onFinish, onSuccess }));
	};

	const remainingBudget = useMemo(() => {
		if (!offer) {
			return 0;
		}
		const diff = offer.amountGoal - offer.amountSpent;

		return diff <= 0 ? 0 : Math.round(diff).toLocaleString();
	}, [offer]);

	const progressPercentages = useMemo(() => {
		if (!offer) {
			return 0;
		}

		const percentages = Math.round(
			(offer.amountSpent * 100) / offer.amountGoal,
		);

		return percentages > 100 ? 100 : percentages;
	}, [offer]);

	const progressBarColor = useMemo(() => {
		switch (offer?.status) {
			case "Active":
				return offerColors.active.progressBar;
			case "Expired":
				return offerColors.expired.progressBar;
			case "Paused":
				return offerColors.paused.progressBar;

			default:
				return null;
		}
	}, [offer?.status]);

	const handleChangeStatusOffer = () => {
		setLoadingChangeStatus(true);
		const newStatus = offer.status === "Active" ? "Paused" : "Active";
		const onFinish = () => setLoadingChangeStatus(false);
		const onSuccess = () => {
			const successMessage =
				newStatus === "Active"
					? "Offer activated successfully"
					: "Offer paused successfully";
			dispatch(
				showSnackBar({
					text: successMessage,
					severity: "success",
					duration: 3000,
				}),
			);
		};
		dispatch(
			changeStatusOffer({
				offerId: offer.id,
				status: newStatus,
				onSuccess,
				onFinish,
			}),
		);
	};

	const handleNavigateDeleteOffer = () => {
		navigate("delete-offer");
	};

	if (!offer) {
		return null;
	}

	return (
		<ModalContainer title={`${offer.name} Offer`} handleClose={handleClose}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					mt: "32px",
					mb: "24px",
				}}
			>
				<LoadingButton
					variant="contained"
					loading={loadingOfferClone}
					onClick={handlePressOfferClone}
					sx={{
						px: "16px",
						height: "40px",
						borderRadius: "10px",
						textTransform: "capitalize",
						fontSize: 16,
						fontWeight: "400",
					}}
				>
					Duplicate Offer
				</LoadingButton>
				{shownEditOfferButton ? (
					<LoadingButton
						sx={{
							px: "16px",
							height: "40px",
							borderRadius: "10px",
							textTransform: "capitalize",
							fontSize: 16,
							fontWeight: "400",
							ml: "8px",
						}}
						variant="contained"
						loading={loadingOfferEdit}
						onClick={handlePressOfferEdit}
					>
						Edit Offer
					</LoadingButton>
				) : null}
				{offer.status === "Active" || offer.status === "Paused" ? (
					<LoadingButton
						sx={{
							ml: "8px",
							px: "16px",
							height: "40px",
							borderRadius: "10px",
							textTransform: "capitalize",
							fontSize: 16,
							fontWeight: "400",
						}}
						variant="contained"
						onClick={handleChangeStatusOffer}
						loading={loadingChangeStatus}
					>
						{`${offer.status === "Active" ? "Pause" : "Activate"} Offer`}
					</LoadingButton>
				) : null}
			</Box>
			<Box
				sx={{
					mb: "24px",
					py: "10px",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Typography
					sx={{
						fontSize: "16px",
						lineHeight: "20px",
						fontWeight: "500",
						color: "black.dark",
					}}
				>
					Offer Status
				</Typography>
				<OfferStatus status={offer.status} shownNotStarted />
			</Box>
			<Box
				sx={{
					mb: "24px",
					py: "10px",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Typography
					sx={{
						fontSize: "16px",
						lineHeight: "20px",
						fontWeight: "500",
						color: "black.dark",
					}}
				>
					Offer Validity
				</Typography>
				<OfferDateStatus
					status={offer.status}
					validFrom={offer.validFrom}
					validTo={offer.validTo}
					expiredAt={offer.expiredAt}
				/>
			</Box>
			<Box
				sx={{
					mb: "24px",
					py: "8px",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Typography
					sx={{
						fontSize: "16px",
						lineHeight: "20px",
						fontWeight: "500",
						color: "black.dark",
					}}
				>
					Remaining Budget
				</Typography>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography
						sx={{
							fontSize: 20,
							lineHeight: "24px",
							color: "black.dark",
							mr: "6px",
						}}
					>
						{`${remainingBudget} `}
						<Box sx={{ fontSize: 12, lineHeight: "14px" }} component="span">
							SAR
						</Box>
					</Typography>
					<Typography
						sx={{ fontSize: 16, lineHeight: "24px", color: "gray.darkDark" }}
					>
						{` /${offer.amountGoal.toLocaleString()} `}
						<Box sx={{ fontSize: 12, lineHeight: "14px" }} component="span">
							SAR
						</Box>
					</Typography>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					mb: "8px",
				}}
			>
				<Typography
					sx={{
						fontSize: "16px",
						lineHeight: "20px",
						fontWeight: "500",
						color: "black.dark",
					}}
				>
					Goal Percentage
				</Typography>

				<Typography
					sx={{
						fontSize: "20px",
						lineHeight: "24px",
						fontWeight: "500",
						color: "black.dark",
					}}
				>
					{`${progressPercentages} `}
					<Box sx={{ fontSize: 12 }} component="span">
						%
					</Box>
				</Typography>
			</Box>
			<Box
				display="flex"
				alignItems="center"
				sx={{
					backgroundColor: "gray.light",
					height: "16px",
					width: "100%",
					borderRadius: 100,
					px: "5px",
					mb: "32px",
				}}
			>
				<Box
					sx={{
						height: "10px",
						backgroundColor: progressBarColor,
						width: `${progressPercentages}%`,
						borderRadius: 100,
					}}
				/>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				<Button
					sx={{
						height: "40px",
						width: "176px",
						borderRadius: "10px",
						textTransform: "capitalize",
						fontSize: 16,
						fontWeight: "400",
					}}
					color="error"
					variant="contained"
					type="submit"
					onClick={handleNavigateDeleteOffer}
				>
					Delete Offer
				</Button>
			</Box>
			<Outlet />
		</ModalContainer>
	);
};
