import { apiAddresses } from "./apiAddresses";
import { authorizedRequest, axiosInstance as axios } from "./axios";

export const apiPostSignIn = (data) => {
	return axios.post(apiAddresses.SIGN_IN, data);
};

export const apiPostSignUp = (data) => {
	return axios.post(apiAddresses.SIGN_UP, data);
};

export const apiPostVerifyEmail = (data) => {
	return axios.post(apiAddresses.VERIFY_EMAIL, data);
};

export const apiPostResendVerificationEmail = (data) => {
	return axios.post(apiAddresses.RESEND_VERIFICATION_EMAIL, data);
};

export const apiPostCheckEmail = (email) => {
	return axios.post(apiAddresses.CHECK_EMAIL, { email });
};

export const apiGetOffers = () => {
	return authorizedRequest(apiAddresses.OFFERS, { method: "GET" });
};

export const apiPostOffer = (offer) => {
	return authorizedRequest(apiAddresses.OFFER, {
		method: "POST",
		data: offer,
	});
};

export const apiPutOffer = (offer) => {
	return authorizedRequest(apiAddresses.OFFER, {
		method: "PUT",
		data: offer,
	});
};

export const apiGetOfferDetails = (offerId) => {
	return authorizedRequest(apiAddresses.OFFER_ID(offerId), {
		method: "GET",
	});
};

export const apiDeleteOffer = (offerId) => {
	return authorizedRequest(apiAddresses.OFFER_ID(offerId), {
		method: "DELETE",
	});
};

export const apiPutOfferChangeStatus = (offerId, status) => {
	return authorizedRequest(apiAddresses.OFFER_CHANGE_STATUS(offerId), {
		method: "PUT",
		data: { status },
	});
};

export const apiGetCustomerPesonas = () => {
	return authorizedRequest(apiAddresses.CUSTOMER_PERSONAS, { method: "GET" });
};

export const apiGetPerformance = (offerId) => {
	return authorizedRequest(apiAddresses.PERFORMANCE(offerId), {
		method: "GET",
	});
};

export const apiGetPerformanceCSV = (offerId) => {
	return authorizedRequest(apiAddresses.PERFORMANCE_CSV(offerId), {
		method: "GET",
	});
};

export const apiGetCatalogs = () => {
	return authorizedRequest(apiAddresses.CATALOGS, {
		method: "GET",
	});
};

export const apiGetCompetitors = (params) => {
	return authorizedRequest(apiAddresses.COMPETITORS, {
		method: "GET",
		params,
	});
};

export const apiGetCustomerBehavior = (params) => {
	return authorizedRequest(apiAddresses.CUSTOMER_BEHAVIOR, {
		method: "GET",
		params,
	});
};

export const apiGetHome = () => {
	return authorizedRequest(apiAddresses.HOME, {
		method: "GET",
	});
};

export const apiGetUserInfo = () => {
	return authorizedRequest(apiAddresses.USER_INFO, {
		method: "GET",
	});
};

export const apiGetCreateBrandData = () => {
	return axios.get(apiAddresses.CREATE_BRAND_DATA);
};

export const apiPostBrand = (newBrand) => {
	return authorizedRequest(apiAddresses.BRAND, {
		method: "POST",
		data: newBrand,
		headers: {
			"Accept": "application/json",
			"Content-Type": "multipart/form-data",
		},
	});
};

export const apiPostCertificate = (
	{ certificatePdf, userId },
	{ onUploadProgress, signal },
) => {
	const formData = new FormData();
	formData.append("certificatePdf", certificatePdf);
	if (userId) {
		formData.append("userId", userId);
	}

	return axios.post(apiAddresses.CERTIFICATE_UPLOAD, formData, {
		headers: {
			"Accept": "application/json",
			"Content-Type": "multipart/form-data",
		},
		onUploadProgress: (progressEvent) => {
			if (onUploadProgress) {
				const progress = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total,
				);
				onUploadProgress(progress);
			}
		},
		signal,
	});
};

export const apiPostVatCertificate = (
	{ vatCertificatePdf, userId },
	{ onUploadProgress, signal },
) => {
	const formData = new FormData();
	formData.append("vatCertificatePdf", vatCertificatePdf);
	if (userId) {
		formData.append("userId", userId);
	}

	return axios.post(apiAddresses.VAT_UPLOAD, formData, {
		headers: {
			"Accept": "application/json",
			"Content-Type": "multipart/form-data",
		},
		onUploadProgress: (progressEvent) => {
			if (onUploadProgress) {
				const progress = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total,
				);
				onUploadProgress(progress);
			}
		},
		signal,
	});
};

export const apiPostReceipt = (receipt, { onUploadProgress, signal }) => {
	return authorizedRequest(apiAddresses.RECEIPT_UPLOAD, {
		method: "POST",
		data: receipt,
		headers: {
			"Accept": "application/json",
			"Content-Type": "multipart/form-data",
		},
		onUploadProgress: (progressEvent) => {
			const progress = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total,
			);
			onUploadProgress(progress);
		},
		signal,
	});
};

export const apiPostTransfer = (transfer) => {
	return authorizedRequest(apiAddresses.RECEIPT_AMOUNT, {
		method: "POST",
		data: transfer,
	});
};

export const apiPostMIDandMCC = (data) => {
	return authorizedRequest(apiAddresses.COMPANY_BRAND_MCC_AND_MID, {
		method: "POST",
		data,
	});
};

export const apiPostFirstBrand = (newBrand, userId) => {
	return axios.post(
		apiAddresses.BRAND,
		{ ...newBrand, userId },
		{
			headers: {
				"Accept": "application/json",
				"Content-Type": "multipart/form-data",
			},
		},
	);
};

export const apiGetBillings = () => {
	return authorizedRequest(apiAddresses.SUBSCRIPTION_DETAILS, {
		method: "GET",
	});
};

export const apiUpdateBillings = (billings) => {
	return authorizedRequest(apiAddresses.SUBSCRIPTION_DETAILS, {
		method: "PUT",
		data: billings,
	});
};

export const apiGetBrand = () => {
	return authorizedRequest(apiAddresses.BRAND, {
		method: "GET",
	});
};

export const apiUpdateBrand = (brand, onUploadProgress) => {
	return authorizedRequest(apiAddresses.UPDATE_BRAND, {
		method: "PUT",
		data: brand,
		headers: {
			"Accept": "application/json",
			"Content-Type": "multipart/form-data",
		},
		onUploadProgress,
	});
};

export const apiUpdateUserDetails = (user) => {
	return authorizedRequest(apiAddresses.USER_INFO, {
		method: "PUT",
		data: user,
	});
};

export const apiUpdatePassword = async (userId, { password, oldPassword }) => {
	return authorizedRequest(apiAddresses.CHANGE_PASSWORD(userId), {
		method: "PUT",
		data: { password, oldPassword },
	});
};
