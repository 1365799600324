import React from "react";
import { Box, ListItemButton, styled } from "@mui/material";
import imageLogo from "../assets/images/mthmr-logo.png";
import { Link, useLocation } from "react-router-dom";
import { colors } from "../styles/colors";
import { useSelector } from "react-redux";
import drawerBackgroundImage from "../assets/images/drawer-background.png";

const ListButton = styled(ListItemButton)(({ theme, open }) => ({
	borderRadius: "10px",
	marginBottom: "16px",
	color: open ? theme.palette.primary.main : colors.blackDark,
	backgroundColor: open ? theme.palette.primary.lightLight : colors.transparent,
	height: "46px",
}));

export const Drawer = () => {
	const selectedBrand = useSelector((state) => state.brands.selectedBrand);
	const location = useLocation();

	return (
		<Box
			sx={{
				borderTopRightRadius: "30px",
				borderBottomRightRadius: "30px",
				border: "1px solid #EFF0F6",
				backgroundColor: "white.main",
				p: "35px 26px",
				position: "relative",
				width: "246px",
			}}
		>
			<img
				src={drawerBackgroundImage}
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
					top: 0,
					left: 0,
					borderTopRightRadius: "30px",
					borderBottomRightRadius: "30px",
				}}
			/>
			<img
				alt="Mthmr-Logo"
				src={imageLogo}
				style={{
					width: "115px",
					height: "34px",
					marginLeft: "16px",
					marginBottom: "40px",
				}}
			/>
			{/* 
			TODO: temporarily hidden
			<ListButton
				LinkComponent={Link}
				to="/"
				open={location.pathname === "/"}
				data-testid="home-page-button"
			>
				Home
			</ListButton> */}
			<ListButton
				LinkComponent={Link}
				to="/offers"
				open={location.pathname.includes("offers")}
				data-testid="offers-page-button"
			>
				Offers
			</ListButton>
			{/* 
			TODO: temporarily hidden
					<ListButton
						LinkComponent={Link}
						to="/competition"
						open={location.pathname === "/competition"}
						data-testid="competition-page-button"
					>
						Competition
					</ListButton> */}
			<ListButton
				LinkComponent={Link}
				to="/customer-behavior"
				open={location.pathname === "/customer-behavior"}
				data-testid="customer-behavior-button"
			>
				Customer Persona
			</ListButton>
			<ListButton
				LinkComponent={Link}
				to="/financial-overview"
				open={location.pathname === "/financial-overview"}
			>
				Financial Overview
			</ListButton>
		</Box>
	);
};
